import React from "react";
import { useToast } from "@chakra-ui/react";

export const Context = React.createContext(undefined);

export default function ContextWrapper({ profile, children }) {
  const toast = useToast();
  const fireToast = (title, description, status) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  return (
    <Context.Provider value={{ fireToast, profile }}>
      {children}
    </Context.Provider>
  );
}

export const useCurrentProfile = () => React.useContext(Context);
