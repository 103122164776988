import {
  Box,
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePickerStyles.css";

const CalendarComponent = ({ callbackFunctionDate, triggerSetter, date, height="40px" }) => {
  const [endDate, setEndDate] = useState(date);
  const [showPicker, setShowPicker] = useState(false);
  const [currentDate, setCurrentDate] = useState(date)
  const datePickerRef = useRef();

  const handleDateSelection = (date) => {
    const selectedDate = new Date(date);
    const lastDayOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0
    );

    setEndDate(lastDayOfMonth);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSave = () => {
    callbackFunctionDate(endDate);
    triggerSetter(true);
    setCurrentDate(endDate)
    setShowPicker(false);
  };

  const formatDisplayDates = () => {
    if (currentDate instanceof Date && !isNaN(currentDate)) {
      return `${currentDate.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      })}`;
    } else {
      return "";
    }
  };

  return (
    <Box ref={datePickerRef} position="relative">
      <InputGroup width="105px" textAlign={"center"}>
        <Input
        height={height}
          readOnly
          value={formatDisplayDates()}
          onClick={() => setShowPicker((prev) => !prev)}
        />
      </InputGroup>
      {showPicker && (
        <Card
          textAlign={"right"}
          p={0}
          zIndex={9999}
          width="250px"
          position="absolute"
          right="0"
          top="100%"
          backgroundColor="white"
          boxShadow="md"
        >
          <CardBody p={0}>
            <Box>
              <DatePicker
                selected={endDate}
                onChange={handleDateSelection}
                endDate={endDate}
                inline
                showMonthYearPicker
                dateFormat="MM/yyyy"
              />
              <Button
                m={"0px 17px 10px"}
                height={"30px"}
                backgroundColor="#536DEC"
                textColor={"white"}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </CardBody>
        </Card>
      )}
    </Box>
  );
};

export default CalendarComponent;
