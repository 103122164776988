import React from "react";
import axios from "axios";
import { usePlaidLink } from "react-plaid-link";
import { Button, IconButton, Tooltip } from "@chakra-ui/react";
import { LinkIcon, RepeatIcon } from "@chakra-ui/icons";

export const LinkButton = ({
  customer_id,
  public_token,
  refreshList,
  setLoading,
  plaid_refresh,
}) => {
  const handleOnSuccess = (public_token, metadata) => {
    setLoading(true);
    axios
      .post("/api/v2/exchange_public_token/", {
        public_token: public_token,
        customer_id: customer_id,
        refresh: plaid_refresh,
      })
      .then((res) => refreshList(customer_id));
  };

  const config = {
    onSuccess: handleOnSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: public_token,
  };
  const { open, ready } = usePlaidLink(config);

  const openPlaidLink = () => {
    if (ready) {
      open();
    }
  };

  return (
    <>
      {plaid_refresh ? (
        <Tooltip label="Click to Relink Plaid Item." fontSize="md">
          <IconButton
            icon={<RepeatIcon />}
            aria-label="Refresh"
            onClick={openPlaidLink}
            isDisabled={!ready}
          />
        </Tooltip>
      ) : (
        <Button
          leftIcon={<LinkIcon boxSize={4} />}
          onClick={openPlaidLink}
          isDisabled={!ready}
        >
          Link bank account
        </Button>
      )}
    </>
  );
};
