import "./App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ClientList } from "./components/client/clientList";
import { VendorsList } from "./components/vendors/vendorsList";
import Navigation from "./components/navigation";
import { Logout } from "./components/logout";
import { ClientDetail } from "./components/client/clientDetail";
import { ClientQuickbooksLink } from "./components/client/clientQuickbooksLink";
import { ChakraProvider } from "@chakra-ui/react";
import { Login } from "./components/login";
import theme from "./theme";
import ThemeView from "./components/themeView";
import ContextWrapper from "./ContextWrapper";
import { ChartOfAccounts } from "./components/account/chartOfAccounts";
import { UserManagement } from "./components/user/userManagement";
import { GrowthPlan } from "./components/dashboard/growthPlan";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const handleStorage = () => {
      if (
        localStorage.getItem("access_token") !== null &&
        localStorage.getItem("access_token") !== "null"
      ) {
        setIsAuth(true);

        axios
          .get(
            "/api/v2/current_profile/",
            { headers: { "Content-Type": "application/json" } },
            { withCredentials: true }
          )
          .then((res) => {
            if (!res.data[0].is_active) {
              setIsAuth(false);
            } else {
              var profile = res.data[0];
              // TODO: remove this temporary hack we're using to limit access to nwe accounting things
              profile.hasAccountingAccess = profile.is_admin;
              profile.isAdmin = profile.type === "admin";
              setProfile(profile);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setIsAuth(false);
      }
    };

    window.addEventListener("storage", handleStorage());
    return () => window.removeEventListener("storage", handleStorage());
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <ContextWrapper profile={profile}>
        <BrowserRouter>
          {isAuth && <Navigation isAdmin={profile?.isAdmin}></Navigation>}
          {profile?.type !== "client" && (
            <Routes>
              <Route path="/" element={<ClientList />} />
              <Route path="/client/:customer_id" element={<ClientDetail />} />
              {profile?.isAdmin && (
                <Route path="/vendors" element={<VendorsList />} />
              )}
              {profile?.isAdmin && (
                <Route
                  path="/chart-of-accounts-template"
                  element={<ChartOfAccounts customer_id={""} template={true} />}
                />
              )}
              {profile?.isAdmin && (
                <Route path="/users" element={<UserManagement />} />
              )}
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/qblink" element={<ClientQuickbooksLink />} />
              <Route path="/themeView" element={<ThemeView />} />
            </Routes>
          )}
          {profile?.type === "client" && (
            <Routes>
              <Route
                path="/"
                element={<GrowthPlan customer_id={profile?.customer} />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
            </Routes>
          )}
        </BrowserRouter>
      </ContextWrapper>
    </ChakraProvider>
  );
}
export default App;
