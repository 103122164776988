import { Box, Card, CardBody, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React from "react";

const MetricCard = ({
  title = "",
  amount = "0.00",
  forecast = "0.00",
  forecast_percentage = "0.00",
  ytd = "0.00",
  ytd_percentage = "0.00",
  forecast_color = "black",
  ytd_color = "black",
}) => {
  return (
    <Stack width="100%" align="center" p="10px 10px">
      <Card width="100%" height={"220px"} borderRadius="20px">
        <CardBody padding={7}>
          <Box align={"left"}>
            <Text fontSize={"large"} margin={0}>
              {title}
            </Text>
            <Text fontSize={"xx-large"} fontWeight={800} margin={0}>
              {amount}
            </Text>
          </Box>
          <SimpleGrid columns={[1, 2]} align="left" p={"10px 0px"}>
            <Box p={0} borderRightWidth={"2px"} width={"85%"} height={"80px"}>
              <Text fontSize={"md"} margin={0} gridColumn={1} gridRow={1}>
                Forecast
              </Text>
              <Text
                fontSize={"22px"}
                margin={0}
                fontWeight={600}
                gridColumn={1}
                gridRow={2}
              >
                {forecast}
              </Text>
              <Text fontSize={"md"} margin={0} gridColumn={1} gridRow={3} color={forecast_color}>
                {forecast_percentage}
              </Text>
            </Box>
            <Box>
              <Text fontSize={"md"} margin={0} gridColumn={2} gridRow={1}>
                YTD Average
              </Text>
              <Text
                fontSize={"22px"}
                margin={0}
                fontWeight={600}
                gridColumn={2}
                gridRow={2}
              >
                {ytd}
              </Text>
              <Text fontSize={"md"} margin={0} gridColumn={2} gridRow={3} color={ytd_color}>
                {ytd_percentage}
              </Text>
            </Box>
          </SimpleGrid>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default MetricCard;
