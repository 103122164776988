import { Card, CardBody, Grid, SimpleGrid, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MetricCard from "./metricCard";
import ProfitCard from "./profitCard";

import axios from "axios";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import MetricExpenseCard from "./metricExpenseCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function customCurrencyFormat(numStr, percentage = false) {
  let num = parseFloat(numStr);
  if (isNaN(num)) return numStr;

  const isNegative = num < 0;

  let absNum = Math.abs(num).toFixed(2);

  let [integerPart, decimalPart] = absNum.split(".");

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const formattedNumber =
    decimalPart !== "00" ? `${integerPart}.${decimalPart}` : integerPart;

  if (percentage) {
    return isNegative ? `-${formattedNumber}%` : `${formattedNumber}%`;
  } else {
    return isNegative ? `-$${formattedNumber}` : `$${formattedNumber}`;
  }
}

const MetricView = ({
  customer_id,
  dashDate,
  trigger,
  triggerSetter,
  triggerExpenseBreakdown,
  setBreakdownData,
}) => {
  const formattedDate = dashDate.toISOString().split("T")[0];
  const [metricData, setMetricData] = useState({
    income: "0.00",
    income_forecast: "0.00",
    income_forecast_percentage: "0.00",
    expense: "0.00",
    expense_forecast: "0.00",
    expense_forecast_percentage: "0.00",
    profit: "0.00",
    cogs: "0.00",
    cogs_percentage: "0.00",
    marketing: "0.00",
    marketing_percentage: "0.00",
    team: "0.00",
    team_percentage: "0.00",
    overhead: "0.00",
    overhead_percentage: "0.00",
    profit_percentage: "0.00",
    ytd_income: "0.00",
    ytd_expense: "0.00",
    ytd_income_percentage: "0.00",
    ytd_expense_percentage: "0.00",
  });

  const [metricGraphData, setMetricGraphData] = useState({
    income: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    expense: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    profit: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    months: ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
  });

  const [pLData, setPLData] = useState({
    overhead_pl: "0.00",
    team_pl: "0.00",
    profit_pl: "0.00",
    marketing_pl: "0.00",
    cogs_pl: "0.00",
  });

  useEffect(() => {
    const refreshMetricData = (id) => {
      if (id) {
        axios
          .get(`/api/v2/customers/${id}/metricview/${formattedDate}/`, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          })
          .then((res) => {
            setMetricData(res.data);
          })
          .catch((err) => console.log(err));
      }
    };
    refreshMetricData(customer_id);
    triggerSetter(false);
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const refreshMetricGraphData = (id) => {
      if (id) {
        axios
          .get(`/api/v2/customers/${id}/metricgraph/${formattedDate}/`, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          })
          .then((res) => {
            setMetricGraphData(res.data);
          })
          .catch((err) => console.log(err));
      }
    };
    refreshMetricGraphData(customer_id);
    triggerSetter(false);
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const refreshPLData = (id) => {
      if (id) {
        axios
          .get(`/api/v2/customers/${id}/plvalues/${formattedDate}/`, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          })
          .then((res) => {
            setPLData(res.data);
          })
          .catch((err) => console.log(err));
      }
    };
    refreshPLData(customer_id);
    triggerSetter(false);
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  function colorFormat(numStr, oposite = false) {
    let num = parseFloat(numStr);
    if (isNaN(num)) return "black";
    if (num === 0) return "black";

    const isNegative = num < 0;

    if (oposite) {
      if (isNegative) {
        return "#68BA65";
      } else {
        return "#FF5E5E";
      }
    } else {
      if (isNegative) {
        return "#FF5E5E";
      } else {
        return "#68BA65";
      }
    }
  }

  function colorExpenseFormat(pl, percentage, oposite = false, profit = false) {
    let num = parseFloat(pl);
    let percent = parseFloat(percentage);

    if (isNaN(num) || isNaN(percent)) {
      return profit ? "#536DEC" : "black";
    }

    let percentageOfPl = ((percent - num) / num) * 100;

    if (oposite) {
      if (percentageOfPl >= 30) {
        return "#68BA65";
      } else if (percentageOfPl >= 15) {
        return "#458CFF";
      } else if (percentageOfPl >= 5) {
        return "#F1B20E";
      } else {
        return "#FF5E5E";
      }
    } else {
      if (percentageOfPl > -5) {
        return "#FF5E5E";
      } else if (percentageOfPl > -15) {
        return "#F1B20E";
      } else if (percentageOfPl > -30) {
        return "#458CFF";
      } else {
        return "#68BA65";
      }
    }
  }

  Tooltip.positioners.customPosition = function (context) {
    if (!context || !context.length || !context[0].element) {
      return false; // If no valid data point, don't display tooltip
    }

    const tooltipPosition = {
      x: context[0].element.x, // Snap to the X position of the line point
      y: context[0].element.y, // Snap to the Y position of the line point
    };

    return tooltipPosition;
  };

  const data = {
    labels: metricGraphData
      ? metricGraphData.months
      : ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
    datasets: [
      {
        type: "bar",
        label: "Income",
        borderRadius: {
          topLeft: 10,
          topRight: 10, // This rounds the top corners of the bars
        },
        backgroundColor: "#458CFF", // Blue for Income
        data: metricGraphData
          ? metricGraphData.income
          : [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
        yAxisID: "y",
        order: "2",
        hoverRadius: 0,
        pointHitRadius: 0,
      },
      {
        type: "bar",
        label: "Expenses",
        borderRadius: {
          topLeft: 10,
          topRight: 10, // This rounds the top corners of the bars
        },
        backgroundColor: "#FF5A5C", // Red for Expenses
        data: metricGraphData
          ? metricGraphData.expense
          : [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
        yAxisID: "y",
        order: "3",
        hoverRadius: 0,
        pointHitRadius: 0,
      },
      {
        type: "line",
        label: "Profit",
        borderColor: "#00D26C", // Green for Profit
        borderWidth: 6,
        pointRadius: 0,
        fill: false,
        data: metricGraphData
          ? metricGraphData.profit
          : [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
        yAxisID: "y",
        order: "1",
        hoverRadius: 10,
        pointHitRadius: 30,
        borderCapStyle: "round",
        borderDash: [],
        PointStyle: "circle",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value, index, values) {
            // Get the max value from the scale
            const maxValue = values[values.length - 1].value;

            // Calculate the 1/3rd and 2/3rd points
            const oneThird = maxValue / 3;
            const twoThirds = (maxValue * 2) / 3;

            if (value === 0) {
              return "0";
            }

            // Show values at 1/3rd, 2/3rd, and 3/3rd (which is the max value)
            if (value === oneThird) {
              return oneThird >= 1000 ? `${oneThird / 1000}K` : oneThird; // Show 1/3rd of the max value
            } else if (value === twoThirds) {
              return twoThirds >= 1000 ? `${twoThirds / 1000}K` : twoThirds; // Show 2/3rd of the max value
            } else if (value === maxValue) {
              return maxValue >= 1000 ? `${maxValue / 1000}K` : maxValue; // Show 3/3rd (max value)
            }

            // Hide all other values (0 is shown by default)
            return "";
          },
        },
      },
    },

    plugins: {
      title: {
        display: true, // Show the title
        align: "start",
        lineHeight: 0.1,
        text: "12-Month Income, Expenses, Profit", // Set the title text
        font: {
          size: 18, // Set the font size of the title
          weight: "bold", // Set the font weight of the title
          family: "Arial", // Set the font family (optional)
        },
        padding: {
          top: 0,
          bottom: 0,
        },
      },
      hover: {
        mode: null,
      },
      tooltip: {
        filter: function (tooltipItem) {
          if (tooltipItem.dataset.type === "line") {
            return true;
          } else {
            return false;
          }
        },

        mode: "index",
        position: "customPosition",
        yAlign: "bottom",
        intersect: false,
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        footerColor: "black",
        borderWidth: 1,
        borderRadius: "5px",
        borderColor: "rgba(0, 0, 0, 0.1)",
        cornerRadius: 8,
        displayColors: true,
        boxWidth: 10,
        boxHeight: 10,
        titleFont: {
          size: 15,
          weight: "normal",
        },
        bodyFont: {
          size: 15,
          weight: "normal",
        },
        footerFont: {
          size: 15,
          weight: "normal",
        },

        callbacks: {
          title: function () {
            return null; // Return an empty string to remove the title
          },

          label: function (tooltipItem) {
            const profitValue = tooltipItem.raw; // Get the Profit value

            // Create an array for the labels
            const label = [
              `Profit: ${customCurrencyFormat(profitValue.toLocaleString())}`,
            ];

            return label; // Return the array of labels
          },
          labelColor: function (tooltipItem) {
            return {
              borderColor: "lime",
              backgroundColor: "lime",
            };
          },
          footer: function (tooltipItems) {
            const tooltipItem = tooltipItems[0];
            const index = tooltipItem.dataIndex;
            const chart = tooltipItem.chart;

            // Access values from different datasets
            const incomeValue = chart.data.datasets[0].data[index];
            const expensesValue = chart.data.datasets[1].data[index];

            return [
              `Income: ${customCurrencyFormat(incomeValue.toLocaleString())}`,
              `Expenses: ${customCurrencyFormat(
                expensesValue.toLocaleString()
              )}`,
            ];
          },
        },
      },
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          generateLabels: function (chart) {
            const data = chart.data;
            return data.datasets.map((dataset, i) => {
              return {
                text: dataset.label,
                fillStyle: dataset.backgroundColor || dataset.borderColor,
                hidden: !chart.isDatasetVisible(i),
                index: i,
                lineWidth: 0,
                height: 20,
                width: 20,
                strokeStyle: dataset.borderColor,
              };
            });
          },
          // Custom legend item drawing function
          render: function (legendItem, chart) {
            const ctx = chart.ctx;
            ctx.fillStyle = legendItem.fillStyle;
            ctx.fillRect(
              legendItem.x,
              legendItem.y,
              legendItem.width,
              legendItem.height
            );
            ctx.strokeStyle = legendItem.strokeStyle || legendItem.fillStyle;
            ctx.strokeRect(
              legendItem.x,
              legendItem.y,
              legendItem.width,
              legendItem.height
            );
          },
        },
      },
      layout: {
        padding: {
          top: 0, // Reduce the top padding of the whole chart
          bottom: 0,
        },
      },
    },
  };

  return (
    <Stack p="5px 20px">
      <Grid
        templateColumns="2fr 1fr 2fr"
        spacing={2}
        p="0px 0px 10px 0px"
        alignItems="center"
        justifyItems="center"
      >
        <Stack width="100%" align="center" gridColumn={1} gridRow={"span 2"}>
          <MetricCard
            title="Total Income"
            amount={customCurrencyFormat(metricData ? metricData.income : 0.0)}
            ytd={customCurrencyFormat(metricData ? metricData.ytd_income : 0.0)}
            ytd_percentage={customCurrencyFormat(
              metricData ? metricData.ytd_income_percentage : 0.0,
              true
            )}
            forecast={customCurrencyFormat(
              metricData ? metricData.income_forecast : 0.0
            )}
            forecast_percentage={customCurrencyFormat(
              metricData ? metricData.income_forecast_percentage : 0.0,
              true
            )}
            forecast_color={colorFormat(
              metricData ? metricData.income_forecast_percentage : "0.00"
            )}
            ytd_color={colorFormat(
              metricData ? metricData.ytd_income_percentage : "0.00"
            )}
          />
        </Stack>

        <Stack align="center" gridColumn={2} gridRow="span 2" width="320px">
          <ProfitCard
            profit={customCurrencyFormat(metricData ? metricData.profit : 0.0)}
            profitPercentage={customCurrencyFormat(
              metricData ? metricData.profit_percentage : 0.0,
              true
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.profit_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.profit_pl : "0.00",
              metricData ? metricData.profit_percentage : "0.00",
              true,
              true
            )}
          />
        </Stack>
        <Stack width="100%" align="center" gridColumn={3} gridRow={"span 2"}>
          <MetricCard
            title="Total Expenses"
            amount={customCurrencyFormat(metricData ? metricData.expense : 0.0)}
            ytd={customCurrencyFormat(
              metricData ? metricData.ytd_expense : 0.0
            )}
            ytd_percentage={customCurrencyFormat(
              metricData ? metricData.ytd_expense_percentage : 0.0,
              true
            )}
            forecast={customCurrencyFormat(
              metricData ? metricData.expense_forecast : 0.0
            )}
            forecast_percentage={customCurrencyFormat(
              metricData ? metricData.expense_forecast_percentage : 0.0,
              true
            )}
            forecast_color={colorFormat(
              metricData ? metricData.expense_forecast_percentage : "0.00",
              true
            )}
            ytd_color={colorFormat(
              metricData ? metricData.ytd_expense_percentage : "0.00",
              true
            )}
          />
        </Stack>
      </Grid>
      <Card height={"300px"} width={"100%"}>
        <CardBody>
          <Bar data={data} options={options} />
        </CardBody>
      </Card>
      <SimpleGrid templateColumns="1fr 1fr 1fr 1fr">
        <MetricExpenseCard
          title="Team"
          total={customCurrencyFormat(metricData ? metricData.team : 0.0)}
          percentage={customCurrencyFormat(
            metricData ? metricData.team_percentage : 0.0,
            true
          )}
          perfectPL={customCurrencyFormat(pLData ? pLData.team_pl : 0.0, true)}
          color={colorExpenseFormat(
            pLData ? pLData.team_pl : "0.00",
            metricData ? metricData.team_percentage : "0.00"
          )}
          type="team"
          triggerExpenseBreakdown={triggerExpenseBreakdown}
          setBreakdownData={setBreakdownData}
        />
        <MetricExpenseCard
          title="Marketing"
          total={customCurrencyFormat(metricData ? metricData.marketing : 0.0)}
          percentage={customCurrencyFormat(
            metricData ? metricData.marketing_percentage : 0.0,
            true
          )}
          perfectPL={customCurrencyFormat(
            pLData ? pLData.marketing_pl : 0.0,
            true
          )}
          color={colorExpenseFormat(
            pLData ? pLData.marketing_pl : "0.00",
            metricData ? metricData.marketing_percentage : "0.00"
          )}
          type="marketing"
          triggerExpenseBreakdown={triggerExpenseBreakdown}
          setBreakdownData={setBreakdownData}
        />
        <MetricExpenseCard
          title="Overhead"
          total={customCurrencyFormat(metricData ? metricData.overhead : 0.0)}
          percentage={customCurrencyFormat(
            metricData ? metricData.overhead_percentage : 0.0,
            true
          )}
          perfectPL={customCurrencyFormat(
            pLData ? pLData.overhead_pl : 0.0,
            true
          )}
          color={colorExpenseFormat(
            pLData ? pLData.overhead_pl : "0.00",
            metricData ? metricData.overhead_percentage : "0.00"
          )}
          type="overhead"
          triggerExpenseBreakdown={triggerExpenseBreakdown}
          setBreakdownData={setBreakdownData}
        />
        <MetricExpenseCard
          title="Products (COGS)"
          total={customCurrencyFormat(metricData ? metricData.cogs : 0.0)}
          percentage={customCurrencyFormat(
            metricData ? metricData.cogs_percentage : 0.0,
            true
          )}
          perfectPL={customCurrencyFormat(pLData ? pLData.cogs_pl : 0.0, true)}
          color={colorExpenseFormat(
            pLData ? pLData.cogs_pl : "0.00",
            metricData ? metricData.cogs_percentage : "0.00"
          )}
          type="cogs"
          triggerExpenseBreakdown={triggerExpenseBreakdown}
          setBreakdownData={setBreakdownData}
        />
      </SimpleGrid>
    </Stack>
  );
};

export default MetricView;
