import React, { useState, useEffect } from 'react';
import {
    Flex, Button, FormControl, FormLabel, Input, FormHelperText, FormErrorMessage, VStack, Box, Image, Icon, useDisclosure, useColorModeValue, Text,
} from '@chakra-ui/react';
import { FaImage, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import PopupUpdate from '../popup_update';
import { Context } from '../../ContextWrapper';
import { DropDown } from '../generic/dropDown';

const VendorUpdate = ({ vendor, updateVendorInList, accountList }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const context = React.useContext(Context);
    const [vendorData, setVendorData] = useState({
        name: '',
        website: '',
        account: '',
        logo: null,
    });

    const formattedAccountList = accountList.map(account => {
        return {
            value: account.id,
            label: account.number + " - " + account.name
        };
    });

    const [initialVendorData, setInitialVendorData] = useState({
        name: '',
        logo: null,
    });

    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        setVendorData({ name: vendor.name, logo: vendor.logo, website: vendor.website, description: vendor.description, account: vendor.account});
        setInitialVendorData({ name: vendor.name, logo: vendor.logo,  website: vendor.website, description: vendor.description, account: vendor.account });
        if (vendor.logo) {
            setImagePreview(vendor.logo);
        }
    }, [vendor]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setVendorData({ ...vendorData, [name]: value });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setVendorData({ ...vendorData, logo: file });
        setImagePreview(URL.createObjectURL(file));
    };

    const handleRemoveImage = () => {
        vendorData.logo = '' ;
        setVendorData({ ...vendorData, logo: vendorData.logo });
        setImagePreview(null);
    };

    const handleSelectAccount = (account) => {
        setVendorData({ ...vendorData, account: account.value });
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        setLoading(true);

        const hasChanged = vendorData.name !== vendor.name ||
            vendorData.website !== vendor.website ||
            vendorData.description !== vendor.description ||
            vendorData.account !== vendor.account?.id ||
            vendorData.logo !== vendor.logo ||
            vendorData.account !== vendor.account;

        if (!hasChanged) {
            setLoading(false);
            onClose();
            return;
        }

        const formData = new FormData();

        formData.append('name', vendorData.name);
        if (vendorData.website) {
            formData.append('website', vendorData.website);
        }
        formData.append('description', vendorData.description);
        formData.append('is_active', true);

        if (vendorData.account) {
            formData.append('account', vendorData.account);
        }

        if (vendorData.logo && vendorData.logo !== initialVendorData.logo) {
            formData.append('logo', vendorData.logo);
        }

        try {
            const response = await axios.put(`/api/v2/vendors/${vendor.id}/`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            });

            if (response.status === 200) {
                updateVendorInList(response.data);
                onClose();
            } else {
                setError(response.request.responseText);
                setVendorData({ ...vendorData, logo: null });
                setImagePreview(null);
                context.fireToast("Error", response.request.responseText, "error");
            }
        } catch (error) {
            console.error('There was an error updating the vendor!', error);
        } finally {
            setLoading(false);
        }
    };

    const borderColor = useColorModeValue("blue.500", "blue.700");
    const backgroundColor = useColorModeValue("blue.50", "gray.700");

    return (
        <div>
            <Button onClick={onOpen}>Update</Button>
            <PopupUpdate
                isOpen={isOpen}
                onClose={onClose}
                title="Update Vendor"
                body="Please update the vendor details below."
                confirmText="Update"
                confirmAction={handleUpdate}
                loading={loading}
                errorMessage={error}
            >
                <VStack spacing={4} as="form" onSubmit={handleUpdate}>
                    <FormControl id="vendorName" isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input
                            type="text"
                            name="name"
                            value={vendorData.name}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl id="website">
                        <FormLabel>Website</FormLabel>
                        <Input
                            type="text"
                            name="website"
                            value={vendorData.website}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl id="description">
                        <FormLabel>Description</FormLabel>
                        <Input
                            type="text"
                            name="description"
                            value={vendorData.description}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl id="account">
                        <FormLabel>Account</FormLabel>
                        <DropDown name="account" options={formattedAccountList} selectedValue={vendorData.account} onChange={handleSelectAccount} />
                    </FormControl>
                    <FormControl id="logo">
                        <FormLabel>Vendor Logo</FormLabel>
                        <Box
                            borderWidth="2px"
                            borderStyle="dashed"
                            borderColor={borderColor}
                            borderRadius="lg"
                            backgroundColor={backgroundColor}
                            width="100%"
                            maxW="400px"
                            height="150px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            cursor="pointer"
                            _hover={{ borderColor: "blue.300" }}
                            position="relative"
                        >
                            <Icon as={FaImage} boxSize={10} color="gray.400" />
                            <Text mt={2}>
                                <Text as="span" fontWeight="bold" color="blue.500">
                                    Click to upload
                                </Text>{" "}
                                or drag and drop
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                                Maximum file size of 1 MB
                            </Text>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                opacity="0"
                                position="absolute"
                                width="100%"
                                height="100%"
                                cursor="pointer"
                            />
                        </Box>
                        <FormHelperText>Choose an image file to upload.</FormHelperText>
                        {imagePreview && (
                            <Flex alignItems="center" mt={2}>
                                <Box>
                                    <Image
                                        src={imagePreview}
                                        alt="Image preview"
                                        objectFit="cover"
                                        borderRadius="md"
                                    />
                                    <Button
                                        leftIcon={<FaTrash />}
                                        colorScheme="red"
                                        variant="outline"
                                        mt={2}
                                        onClick={handleRemoveImage}
                                    >
                                        Remove Image
                                    </Button>
                                </Box>
                            </Flex>
                        )}
                    </FormControl>
                    {error && (
                        <FormErrorMessage>{error}</FormErrorMessage>
                    )}
                </VStack>
            </PopupUpdate>
        </div>
    );
};

export default VendorUpdate;
