import {
  Box,
  Card,
  CardBody,
  Container,
  Grid,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CalendarComponent from "./calendarComponent";
import ExpenseCard from "./expenseCard";
import GrowthPlanCard from "./growthPlanCard";
import PerformanceReview from "./performanceReview";
import ProfitCard from "./profitCard";
import ExpenseBreakdown from "./expense_breakdown";

const ResourceCard = ({ title, description, listing, column }) => {
  return (
    <Stack
      width="100%"
      align="center"
      gridColumn={column}
      p="15px 10px"
      position={"relative"}
    >
      <Card width="100%" height="115px" borderRadius="20px">
        <CardBody textAlign={"center"}>
          <Box p={"4px 0 0 0"}>
            <Text fontSize={"x-large"} fontWeight={600}>
              {title}
            </Text>
            <Text>{description}.</Text>
          </Box>
        </CardBody>
      </Card>

      <Card
        position="absolute"
        top="-15px"
        left="50%"
        transform="translateX(-50%)"
        width="50px"
        height="50px"
        borderRadius="100%"
        bg="#536DEC"
      >
        <CardBody p={"10px 0 0 0"}>
          <Text textAlign="center" fontSize={"20px"} color="white">
            {listing}
          </Text>
        </CardBody>
      </Card>
    </Stack>
  );
};

const ResourceOverview = ({ resourceOverviewData }) => {
  return (
    <Stack p={"20px 0 0 0"}>
      {resourceOverviewData ? (
        resourceOverviewData.first_recommended_resource &&
        resourceOverviewData.second_recommended_resource &&
        resourceOverviewData.third_recommended_resource ? (
          <SimpleGrid
            templateColumns="1fr 1fr 1fr"
            spacing={2}
            p="5px"
            alignItems="center"
            justifyItems="center"
          >
            <ResourceCard
              title={resourceOverviewData.first_recommended_resource}
              description={"Lorum Ipsum"}
              listing={1}
              column={1}
            />
            <ResourceCard
              title={resourceOverviewData.second_recommended_resource}
              description={"Lorum Ipsum"}
              listing={2}
              column={2}
            />
            <ResourceCard
              title={resourceOverviewData.third_recommended_resource}
              description={"Lorum Ipsum"}
              listing={3}
              column={3}
            />
          </SimpleGrid>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {resourceOverviewData ? (
        resourceOverviewData.first_recommended_resource &&
        resourceOverviewData.second_recommended_resource &&
        !resourceOverviewData.third_recommended_resource ? (
          <SimpleGrid
            templateColumns="1fr 1fr"
            spacing={2}
            p="5px"
            alignItems="center"
            justifyItems="center"
          >
            <ResourceCard
              title={resourceOverviewData.first_recommended_resource}
              description={"Lorum Ipsum"}
              listing={1}
              column={1}
            />
            <ResourceCard
              title={resourceOverviewData.second_recommended_resource}
              description={"Lorum Ipsum"}
              listing={2}
              column={2}
            />
          </SimpleGrid>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {resourceOverviewData ? (
        resourceOverviewData.first_recommended_resource &&
        !resourceOverviewData.second_recommended_resource &&
        !resourceOverviewData.third_recommended_resource ? (
          <SimpleGrid
            templateColumns="1fr 1fr 1fr"
            spacing={2}
            p="5px"
            alignItems="center"
            justifyItems="center"
          >
            <ResourceCard
              title={resourceOverviewData.first_recommended_resource}
              description={"Lorum Ipsum"}
              listing={1}
              column={2}
            />
          </SimpleGrid>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      <Stack width="100%" align="center" p="0px 5px 10px 5px">
        {resourceOverviewData ? (
          resourceOverviewData.performance_overview ? (
            <Card width="100%" height="150px" borderRadius="20px">
              <CardBody textAlign={"center"}>
                <Box>
                  <Text fontSize={"x-large"} lineHeight={"10px"}>
                    Performance Overview
                  </Text>
                  <Stack
                    as="span"
                    display="inline-block"
                    borderTop="2px solid #536DEC"
                    width="150px"
                    textAlign="center"
                  ></Stack>
                </Box>
                <Text>{resourceOverviewData.performance_overview}</Text>
              </CardBody>
            </Card>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
};

const GrowthPlanContent = ({ customer_id, date, trigger, triggerSetter }) => {
  const formattedDate = date.toISOString().split("T")[0];
  const [growthPlanData, setGrowthPlanData] = useState({
    income: "0.00",
    income_forecast: "0.00",
    income_forecast_percentage: "0.00",
    cash_on_hand: "0.00",
    last_cash_on_hand: "0.00",
    last_cash_on_hand_percentage: "0.00",
    expense: "0.00",
    expense_forecast: "0.00",
    expense_forecast_percentage: "0.00",
    equity: "0.00",
    last_equity: "0.00",
    last_equity_percentage: "0.00",
    profit: "0.00",
    profit_percentage: "0.00",
    team_percentage: "0.00",
    marketing_percentage: "0.00",
    overhead_percentage: "0.00",
    cogs_percentage: "0.00",
    team: "0.00",
    marketing: "0.00",
    overhead: "0.00",
    cogs: "0.00",
  });

  const [resourceOverview, setResourceOverview] = useState({
    first_recommended_resource: null,
    second_recommended_resource: null,
    third_recommended_resource: null,
    performance_overview: null,
  });

  const [pLData, setPLData] = useState({
    overhead_pl: "0.00",
    team_pl: "0.00",
    profit_pl: "0.00",
    marketing_pl: "0.00",
    cogs_pl: "0.00",
  });

  useEffect(() => {
    const refreshGrowthPlanData = (id) => {
      if (id) {
        axios
          .get(`/api/v2/customers/${id}/growthplan/${formattedDate}/`, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          })
          .then((res) => {
            setGrowthPlanData(res.data);
          })
          .catch((err) => console.log(err));
      }
    };
    refreshGrowthPlanData(customer_id);
    triggerSetter(false);
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const refreshResourceOverview = (id) => {
      if (id) {
        axios
          .get(
            `/api/v2/customers/${id}/resourceperformanceview/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then((res) => {
            setResourceOverview(res.data);
          })
          .catch((err) => console.log(err));
      }
    };
    refreshResourceOverview(customer_id);
    triggerSetter(false);
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const refreshPLData = (id) => {
      if (id) {
        axios
          .get(`/api/v2/customers/${id}/plvalues/${formattedDate}`, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          })
          .then((res) => {
            setPLData(res.data);
          })
          .catch((err) => console.log(err));
      }
    };
    refreshPLData(customer_id);
    triggerSetter(false);
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  function customCurrencyFormat(numStr, percentage = false) {
    let num = parseFloat(numStr);
    if (isNaN(num)) return numStr;

    const isNegative = num < 0;

    let absNum = Math.abs(num).toFixed(2);

    let [integerPart, decimalPart] = absNum.split(".");

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const formattedNumber =
      decimalPart !== "00" ? `${integerPart}.${decimalPart}` : integerPart;

    if (percentage) {
      return isNegative ? `-${formattedNumber}%` : `${formattedNumber}%`;
    } else {
      return isNegative ? `-$${formattedNumber}` : `$${formattedNumber}`;
    }
  }

  function colorFormat(numStr, oposite = false) {
    let num = parseFloat(numStr);
    if (isNaN(num)) return "black";
    if (num === 0) return "black";

    const isNegative = num < 0;

    if (oposite) {
      if (isNegative) {
        return "#68BA65";
      } else {
        return "#FF5E5E";
      }
    } else {
      if (isNegative) {
        return "#FF5E5E";
      } else {
        return "#68BA65";
      }
    }
  }

  function colorExpenseFormat(pl, percentage, oposite = false, profit = false) {
    let num = parseFloat(pl);
    let percent = parseFloat(percentage);

    if (isNaN(num) || isNaN(percent)) {
      return profit ? "#536DEC" : "black";
    }

    let percentageOfPl = ((percent - num) / num) * 100;

    if (oposite) {
      if (percentageOfPl >= 30) {
        return "#68BA65";
      } else if (percentageOfPl >= 15) {
        return "#458CFF";
      } else if (percentageOfPl >= 5) {
        return "#F1B20E";
      } else {
        return "#FF5E5E";
      }
    } else {
      if (percentageOfPl > -5) {
        return "#FF5E5E";
      } else if (percentageOfPl > -15) {
        return "#F1B20E";
      } else if (percentageOfPl > -30) {
        return "#458CFF";
      } else {
        return "#68BA65";
      }
    }
  }

  return (
    <Stack>
      <Grid
        templateColumns="2fr 1fr 2fr"
        spacing={2}
        p="25px 10px 10px 5px"
        alignItems="center"
        justifyItems="center"
      >
        <Stack
          width="100%"
          align="center"
          gridColumn={1}
          gridRow={1}
          p="15px 10px"
        >
          <GrowthPlanCard
            leftTitle="Total Income"
            rightTitle="Forecast"
            leftAmount={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.income : 0.0}`
            )}
            rightAmount={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.income_forecast : 0.0}`
            )}
            rightPercentage={customCurrencyFormat(
              `${
                growthPlanData ? growthPlanData.income_forecast_percentage : 0.0
              }`,
              true
            )}
            cardHeight="120px"
            cardWidth="100%"
            color={colorFormat(
              growthPlanData
                ? growthPlanData.income_forecast_percentage
                : "0.00"
            )}
          />
        </Stack>
        <Stack
          width="100%"
          align="center"
          gridColumn={1}
          gridRow={2}
          p="15px 10px"
        >
          <GrowthPlanCard
            leftTitle="Cash on Hand"
            rightTitle="Last Month"
            leftAmount={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.cash_on_hand : 0.0}`
            )}
            rightAmount={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.last_cash_on_hand : 0.0}`
            )}
            rightPercentage={customCurrencyFormat(
              `${
                growthPlanData
                  ? growthPlanData.last_cash_on_hand_percentage
                  : 0.0
              }`,
              true
            )}
            cardHeight="120px"
            cardWidth="100%"
            color={colorFormat(
              growthPlanData
                ? growthPlanData.last_cash_on_hand_percentage
                : "0.00"
            )}
          />
        </Stack>
        <Stack align="center" gridColumn={2} gridRow="span 2" width="320px">
          <ProfitCard
            profit={customCurrencyFormat(
              growthPlanData ? growthPlanData.profit : 0.0
            )}
            profitPercentage={customCurrencyFormat(
              growthPlanData ? growthPlanData.profit_percentage : 0.0,
              true
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.profit_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.profit_pl : "0.00",
              growthPlanData ? growthPlanData.profit_percentage : "0.00",
              true,
              true
            )}
            status={true}
            data={growthPlanData ? growthPlanData : {}}
          />
        </Stack>
        <Stack
          width="100%"
          align="center"
          gridColumn={3}
          gridRow={1}
          p="15px 10px"
        >
          <GrowthPlanCard
            leftTitle="Total Expenses"
            rightTitle="Forecast"
            leftAmount={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.expense : 0.0}`
            )}
            rightAmount={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.expense_forecast : 0.0}`
            )}
            rightPercentage={customCurrencyFormat(
              `${
                growthPlanData
                  ? growthPlanData.expense_forecast_percentage
                  : 0.0
              }`,
              true
            )}
            cardHeight="120px"
            cardWidth="100%"
            color={colorFormat(
              growthPlanData
                ? growthPlanData.expense_forecast_percentage
                : "0.00",
              true
            )}
          />
        </Stack>
        <Stack
          width="100%"
          align="center"
          gridColumn={3}
          gridRow={2}
          p="15px 10px"
        >
          <GrowthPlanCard
            leftTitle="Equity"
            rightTitle="Last Month"
            leftAmount={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.equity : 0.0}`
            )}
            rightAmount={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.last_equity : 0.0}`
            )}
            rightPercentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.last_equity_percentage : 0.0}`,
              true
            )}
            cardHeight="120px"
            cardWidth="100%"
            color={colorFormat(
              growthPlanData ? growthPlanData.last_equity_percentage : "0.00"
            )}
          />
        </Stack>
      </Grid>
      <SimpleGrid
        templateColumns="1fr 1fr 1fr 1fr"
        spacing={2}
        p="5px"
        alignItems="center"
        justifyItems="center"
      >
        <ExpenseCard
          title="Team"
          percentage={customCurrencyFormat(
            `${growthPlanData ? growthPlanData.team_percentage : 0.0}`,
            true
          )}
          total={customCurrencyFormat(
            `${growthPlanData ? growthPlanData.team : 0.0}`
          )}
          perfectPL={customCurrencyFormat(pLData ? pLData.team_pl : 0.0, true)}
          color={colorExpenseFormat(
            pLData ? pLData.team_pl : "0.00",
            growthPlanData ? growthPlanData.team_percentage : "0.00"
          )}
        />
        <ExpenseCard
          title="Marketing"
          percentage={customCurrencyFormat(
            `${growthPlanData ? growthPlanData.marketing_percentage : 0.0}`,
            true
          )}
          total={customCurrencyFormat(
            `${growthPlanData ? growthPlanData.marketing : 0.0}`
          )}
          perfectPL={customCurrencyFormat(
            pLData ? pLData.marketing_pl : 0.0,
            true
          )}
          color={colorExpenseFormat(
            pLData ? pLData.marketing_pl : "0.00",
            growthPlanData ? growthPlanData.marketing_percentage : "0.00"
          )}
        />
        <ExpenseCard
          title="Overheads"
          percentage={customCurrencyFormat(
            `${growthPlanData ? growthPlanData.overhead_percentage : 0.0}`,
            true
          )}
          total={customCurrencyFormat(
            `${growthPlanData ? growthPlanData.overhead : 0.0}`
          )}
          perfectPL={customCurrencyFormat(
            pLData ? pLData.overhead_pl : 0.0,
            true
          )}
          color={colorExpenseFormat(
            pLData ? pLData.overhead_pl : "0.00",
            growthPlanData ? growthPlanData.overhead_percentage : "0.00"
          )}
        />
        <ExpenseCard
          title="Products (COGS)"
          percentage={customCurrencyFormat(
            `${growthPlanData ? growthPlanData.cogs_percentage : 0.0}`,
            true
          )}
          total={customCurrencyFormat(
            `${growthPlanData ? growthPlanData.cogs : 0.0}`
          )}
          perfectPL={customCurrencyFormat(pLData ? pLData.cogs_pl : 0.0, true)}
          color={colorExpenseFormat(
            pLData ? pLData.cogs_pl : "0.00",
            growthPlanData ? growthPlanData.cogs_percentage : "0.00"
          )}
        />
      </SimpleGrid>
      <ResourceOverview resourceOverviewData={resourceOverview} />
    </Stack>
  );
};

export const GrowthPlan = ({ customer_id }) => {
  const [tab, setTab] = useState("growthPlan");
  const [triggerRefesh, setTriggerRefresh] = useState(true);
  const [expenseBreakdown, setExpenseBreakdown] = useState(false);
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );
  const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
  lastDayOfPreviousMonth.setDate(0);

  const [dashDate, setDashDate] = useState(lastDayOfPreviousMonth);
  const [breakdownData, setBreakdownData] = useState({ title: "", type: "" });
  const updateDate = (newDate) => {
    setDashDate(new Date(newDate));
  };

  return (
    <Container maxW="100%" bg="#F4F4F2" h="100%" p="0px" overflowY="auto">
      {!expenseBreakdown ? (
        <>
          <Card
            borderRadius={0}
            width="100%"
            h="75px"
            border="1px solid #e0e0e0"
          >
            <Tabs index={tab === "performanceReview" ? 1 : 0}>
              <Grid
                templateColumns="1fr 2fr 9fr"
                width="100%"
                align="center"
                p="25px 20px 0px"
              >
                <TabList>
                  <Tab
                    _focus={{
                      outline: "none",
                      border: "none",
                      boxShadow: "none",
                    }}
                    _selected={{
                      borderBottom: "3px solid #536DEC",
                      color: "#536DEC",
                    }}
                    _active={{ background: "none" }}
                    cursor="pointer"
                    onClick={() => setTab("growthPlan")}
                    width="90px"
                    h="50px"
                    p={0}
                  >
                    <Text fontWeight="bold">Growth Plan</Text>
                  </Tab>
                  <Tab
                    _focus={{
                      outline: "none",
                      border: "none",
                      boxShadow: "none",
                    }}
                    _selected={{
                      borderBottom: "3px solid #536DEC",
                      color: "#536DEC",
                    }}
                    _active={{ background: "none" }}
                    cursor="pointer"
                    onClick={() => setTab("performanceReview")}
                    width="170px"
                    h="50px"
                    p={0}
                    whiteSpace="nowrap"
                  >
                    <Text fontWeight="bold">Performance Review</Text>
                  </Tab>
                </TabList>
                <Stack gridColumn={9} textAlign="right">
                  {tab === "growthPlan" && (
                    <CalendarComponent
                      callbackFunctionDate={updateDate}
                      triggerSetter={setTriggerRefresh}
                      date={dashDate}
                    />
                  )}
                </Stack>
              </Grid>
            </Tabs>
          </Card>

          {tab === "growthPlan" ? (
            <GrowthPlanContent
              customer_id={customer_id}
              date={dashDate}
              trigger={triggerRefesh}
              triggerSetter={setTriggerRefresh}
            />
          ) : (
            <PerformanceReview
              customer_id={customer_id}
              dashDate={dashDate}
              trigger={triggerRefesh}
              triggerSetter={setTriggerRefresh}
              updateDate={updateDate}
              triggerExpenseBreakdown={setExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
          )}
        </>
      ) : (
        <ExpenseBreakdown
          customer_id={customer_id}
          dashDate={dashDate}
          setExpenseBreakdown={setExpenseBreakdown}
          setTab={setTab}
          title={breakdownData.title}
          type={breakdownData.type}
        />
      )}
    </Container>
  );
};
