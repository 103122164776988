import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PopupUpdate from '../popup_update';
import {
    HStack, Button, FormControl, FormLabel, Input, FormHelperText, Box, Image, Icon, useDisclosure, useColorModeValue, Text,
} from '@chakra-ui/react';
import ClientDelete from './clientDelete';
import { Context } from '../../ContextWrapper';
import { FaImage, FaTrash } from 'react-icons/fa';

const ClientUpdate = ({ customer, updateCustomerInList, removeCustomerFromList }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState(false);
    const [clientData, setClientData] = useState({
        name: '',
        primary_contact: '',
        image: null
    });
    const context = React.useContext(Context);

    const [initialClientData, setInitialClientData] = useState({
        name: '',
        logo: null,
    });

    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        setClientData(customer);
        setInitialClientData({ 
            name: customer.name, logo: customer.logo, primary_contact: customer.primary_contact 
        });
        if (customer.logo) {
            setImagePreview(customer.logo);
        }
    }, [customer]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClientData({ ...clientData, [name]: value });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setClientData({ ...clientData, logo: file });
        setImagePreview(URL.createObjectURL(file));
    };

    const handleRemoveImage = () => {
        clientData.logo = '' ;
        setClientData({ ...clientData, logo: clientData.logo });
        setImagePreview(null);
    };

    const handleUpdate = async () => {
        setLoading(true);

        const formData = new FormData();

        formData.append('name', clientData.name);
        formData.append('email', clientData.email);
        formData.append('primary_contact', clientData.primary_contact);
        formData.append('is_active', true);
        
        if (clientData.logo !== initialClientData.logo) {
            formData.append('logo', clientData.logo);
        }

        try {
            const response = await axios.put(`/api/v2/customers/${customer.id}/`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            });
            if (response.status === 200) {
                updateCustomerInList(response.data)
                onClose();
            } else {
                setClientData({ ...clientData, logo: null });
                setImagePreview(null);
                context.fireToast("Error", response['request']['responseText'], "error")
            }
        } catch (error) {
            console.error('There was an error updating the client!', error);
        } finally {
            setLoading(false);
        }
    };

    const borderColor = useColorModeValue("blue.500", "blue.700");
    const backgroundColor = useColorModeValue("blue.50", "gray.700");

    return (
        <div>
            <Button variant="outline" onClick={onOpen}>Edit</Button>
            <PopupUpdate
                isOpen={isOpen}
                onClose={onClose}
                title="Update Client"
                confirmText="Save"
                confirmAction={handleUpdate}
                loading={loading}
                deleteButton={<ClientDelete
                    customer_id={customer.id}
                    removeCustomerFromList={removeCustomerFromList}
                  />}
            >
                <form>
                    <FormControl id="name" isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input
                            type="text"
                            name="name"
                            id="clientName"
                            value={clientData.name}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl id="email" isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            name="email"
                            id="clientEmail"
                            value={clientData.email}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl id="primary_contact">
                        <FormLabel>Primary Contact</FormLabel>
                        <Input
                            type="text"
                            name="primary_contact"
                            id="clientPrimaryContact"
                            value={clientData.primary_contact}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl id="logo">
                        <FormLabel>Facility Logo</FormLabel>
                        <Box
                            borderWidth="2px"
                            borderStyle="dashed"
                            borderColor={borderColor}
                            borderRadius="lg"
                            backgroundColor={backgroundColor}
                            width="100%"
                            maxW="400px"
                            height="150px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            cursor="pointer"
                            _hover={{ borderColor: "blue.300" }}
                            position="relative"
                        >
                            <Icon as={FaImage} boxSize={10} color="gray.400" />
                            <Text mt={2}>
                                <Text as="span" fontWeight="bold" color="blue.500">
                                    Click to upload
                                </Text>{" "}
                                or drag and drop
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                                Maximum file size of 1 MB
                            </Text>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                opacity="0"
                                position="absolute"
                                width="100%"
                                height="100%"
                                cursor="pointer"
                            />
                        </Box>
                        <FormHelperText>Choose an image file to upload.</FormHelperText>
                        {imagePreview && (
                            <Box mt={2}>
                                <HStack spacing={4}>
                                    <Image
                                        src={imagePreview}
                                        alt="Image preview"
                                        objectFit="cover"
                                        borderRadius="md"
                                        boxSize="100px"
                                    />
                                    <Button
                                        leftIcon={<FaTrash />}
                                        colorScheme="red"
                                        variant="outline"
                                        size="sm"
                                        onClick={handleRemoveImage}
                                    >
                                        Remove Image
                                    </Button>
                                </HStack>
                            </Box>
                        )}
                    </FormControl>
                </form>
            </PopupUpdate>
        </div>
    );
};

export default ClientUpdate;
