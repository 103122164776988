import React, { useState, useEffect, useCallback } from "react";
import { Image, Table, Thead, Tbody, Th, Tr, Td, Stack, Container, Heading, Input, Flex, Box, InputGroup, InputRightElement, Spacer, HStack, Button } from "@chakra-ui/react";
import VendorDelete from "./vendorDelete";
import VendorUpdate from "./vendorUpdate";
import { VendorsCreate } from "./vendorsCreate";
import { SearchIcon } from '@chakra-ui/icons'
import axios from "axios";
import CustomTableContainer from "../../theme/components/tableContainer";
import { Context } from "../../ContextWrapper";

export const VendorsList = () => {
  const [vendorsList, setVendorsList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextCursor, setNextCursor] = useState(null);
  const [previousCursor, setPreviousCursor] = useState(null);
  const context = React.useContext(Context);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const refreshVendorsList = useCallback((cursor) => {
    let params = {};
    if (cursor) {
      const urlObj = new URL(cursor);
      params.cursor = urlObj.searchParams.get('cursor');
    }

    if (search) {
      params.name__icontains = search
    }

    axios
      .get("/api/v2/vendors/", {
        headers: { "Content-Type": "application/json" },
        params: params,
        withCredentials: true,
      })
      .then((res) => {
        setVendorsList(res.data.results);
        setNextCursor(res.data.next);
        setPreviousCursor(res.data.previous);
      })
      .catch((err) => console.log(err));
  }, [search]);

  const refreshAccountList = () => {
      const url = '/api/v2/accounts/';
      axios
      .get(url,
          {headers: {'Content-Type': 'application/json'}},
          {withCredentials: true})
      .then((res) => {
          setAccountList(res.data);
      })
      .catch((err) => console.log(err));
  };

  const addVendorToList = (vendor) => {
    setVendorsList((prevList) => [...prevList, vendor]);
    context.fireToast("Success", "Vendor created.", "success");
  };

  const updateVendorInList = (updatedVendor) => {
    setVendorsList((prevList) =>
      prevList.map((vendor) =>
        vendor.id === updatedVendor.id ? updatedVendor : vendor
      )
    );
    context.fireToast("Success", "Vendor updated.", "success");
  };

  const removeVendorFromList = (vendorId) => {
    setVendorsList((prevList) =>
      prevList.filter((vendor) => vendor.id !== vendorId)
    );
    context.fireToast("Success", "Vendor Deleted.", "success");
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      window.location.href = "/login";
    } else {
      refreshVendorsList();
      refreshAccountList();
    }
  }, [refreshVendorsList]);

  const handleNextPage = () => {
    if (nextCursor) {
      refreshVendorsList(nextCursor);
    }
  };

  const handlePreviousPage = () => {
    if (previousCursor) {
      refreshVendorsList(previousCursor);
    }
  };

  const searchVendors = () => {
    refreshVendorsList();
  }

  return (
    <Container maxW="100%" bg="brand.50" h='calc(91vh)'>
      <Stack direction='column' spacing={2} align='center'>
        <CustomTableContainer width="90%">
          <Flex pb="5">
            <Box>
              <Heading as='h3' size='lg'>Vendor Listing</Heading>
            </Box>
            <Spacer />
            <Box>
              <HStack p="0" direction='row' spacing={2} align='center'>
                <VendorsCreate
                  addVendorToList={addVendorToList}
                  accountList={accountList}
                />
                <InputGroup minW="340px">
                  <Input placeholder="Search..." onChange={handleSearch} />
                  <InputRightElement bg='brand.500' borderBottomRightRadius={6} borderTopRightRadius={6}>
                    <SearchIcon variant="white" onClick={searchVendors}/>
                  </InputRightElement>
                </InputGroup>
              </HStack>
            </Box>
          </Flex>
          <Box maxHeight='calc(100vh - 300px)' bg="white" overflowX="auto">
            <Table variant="unstyled">
              <Thead position="sticky" top={-1} zIndex={1}>
                <Tr>
                  <Th>Name</Th>
                  <Th>Account</Th>
                  <Th>Website</Th>
                  <Th style={{width: "118px"}}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  vendorsList.map((vendor) => (
                    <VendorRow
                      key={vendor.id}
                      vendor={vendor}
                      accountList={accountList}
                      updateVendorInList={updateVendorInList}
                      removeVendorFromList={removeVendorFromList}
                    />
                  ))
                }
              </Tbody>
            </Table>
          </Box>
          <Stack direction='row' spacing={4} mt={4} justifyContent="center">
            {previousCursor && (
              <Button onClick={handlePreviousPage}>
                Previous
              </Button>
            )}
            {nextCursor && (
              <Button onClick={handleNextPage} disabled={!nextCursor}>
                Next
              </Button>
            )}
          </Stack>
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};


const VendorRow = ({ vendor, accountList, updateVendorInList, removeVendorFromList }) => {
  var accountText = "";
  const account = accountList.find((a) => a.id === vendor.account);
  if (account) {
      accountText = account.number + " " + account.name;
  }
  return (
    <Tr key={vendor.id}>
      <Td style={{ paddingRight: "0px" }}>
        <Box display="inline-flex" alignItems="center">
          <Image
            src={vendor.logo || `https://ui-avatars.com/api/?size=24&name=${vendor.name}`}
            alt={vendor.name || "Placeholder"}
            boxSize="25px"
            objectFit="cover"
            borderRadius="full"
            mr="2"
          />
          {vendor.name}
        </Box>
      </Td>
      <Td>{accountText}</Td>
      <Td>{vendor.website}</Td>
      <Td pt="2" pb="2">
        <HStack p="0" direction='row' spacing={2} align='center'>
          <VendorUpdate
            vendor={vendor}
            updateVendorInList={updateVendorInList}
            accountList={accountList} />
          <VendorDelete
            vendor_id={vendor.id}
            removeVendorFromList={removeVendorFromList}
          />
        </HStack>
      </Td>
    </Tr>
  )
}
