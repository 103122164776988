import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faUnlink, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { LinkPlaid } from "../plaid/link";
import ClientPLaidItemDelete from "./clientPlaidDelete";
import { PlaidItemCreate } from "../plaid/plaidItemCreate";
import { PlaidBankTransactionExport } from "../plaid/plaidBankTransaction";
import EditQuickbooksName from "../account/editQuickbooksName";
import {
  Alert,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Stack,
  Container,
  Heading,
  Flex,
  Box,
  Spacer,
  HStack,
  Tooltip,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  NumberInputField,
  NumberInput,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
} from "@chakra-ui/react";
import { Context, useCurrentProfile } from "../../ContextWrapper";
import { AlertCircle, Cloud, CloudOff } from "react-feather";
import CustomTableContainer from "../../theme/components/tableContainer";
import { DropDown } from "../generic/dropDown";
import { titleCase } from "../../utils";

export const ClientPlaid = ({ customer_id }) => {
  const [plaidItemList, setPlaidItemList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState({});
  const {
    isOpen: isStartOpen,
    onOpen: onStartOpen,
    onClose: onStartClose,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const context = React.useContext(Context);

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      window.location.href = "/login";
    } else {
      refreshPlaidItemList(customer_id);
      refreshAccountList(customer_id);
      refreshSubscription(customer_id);
      setLoading(false);
    }
  }, [customer_id]);

  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
      setTimeout(() => setMessage(""), 3000);
    }
  }, [location.state]);

  const refreshPlaidItemList = (id) => {
    if (id) {
      setLoading(true);
      axios
        .get(
          `/api/v2/customers/${id}/plaiditems`,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setPlaidItemList(res.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const addPlaidItemToList = (plaid_item) => {
    setPlaidItemList((prevList) => [...prevList, plaid_item]);
    context.fireToast("Success", "Bank created.", "success");
  };

  const removePlaidItemFromList = (plaid_item_id) => {
    setPlaidItemList((prevList) =>
      prevList.filter((plaid_item) => plaid_item.id !== plaid_item_id)
    );
    context.fireToast("Success", "Bank deleted.", "success");
  };

  const refreshAccountList = (id) => {
    if (id) {
      axios
        .get(
          `/api/v2/customers/${id}/accounts/?type__name__in=Bank,Credit Card&is_main_account=false`,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setAccountList(
            res.data.map((a) => ({
              value: a.id,
              label: a.number + " - " + a.name,
            }))
          );
        })
        .catch((err) => console.log(err));
    }
  };

  const refreshSubscription = (id) => {
    if (id) {
      axios
        .get(
          `/api/v2/customers/${id}/subscriptions/?status=active`,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.length > 0) {
            setSubscription(res.data[0]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const openSubscription = () => {
    if (subscription?.status === "active") {
      onViewOpen();
    } else {
      onStartOpen();
    }
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <Stack direction="column" spacing={2} align="center">
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        <CustomTableContainer width="100%">
          <Flex pb="5">
            <Box>
              <Heading as="h3" size="lg">
                Plaid Listing
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <Stack direction="row" spacing={6} align="center">
                {subscription?.status !== "active" && <CloudOff color="red" />}
                {subscription?.status === "active" && <Cloud color="green" />}
                <Button onClick={openSubscription}>Subscription</Button>
                <StartSubscriptionModal
                  isOpen={isStartOpen}
                  onClose={onStartClose}
                  loading={loading}
                  setSubscription={setSubscription}
                  customer_id={customer_id}
                  plaidItemList={plaidItemList}
                />
                <ViewSubscriptionModal
                  isOpen={isViewOpen}
                  onClose={onViewClose}
                  loading={loading}
                  subscription={subscription}
                  setSubscription={setSubscription}
                  customer_id={customer_id}
                />
                <PlaidItemCreate
                  customer_id={customer_id}
                  addPlaidItemToList={addPlaidItemToList}
                />
                <LinkPlaid
                  customer_id={customer_id}
                  refreshList={refreshPlaidItemList}
                  setLoading={setLoading}
                  plaid_refresh={false}
                />
                <div>
                  {loading && (
                    <FontAwesomeIcon
                      className="float-end fa-spin"
                      icon={faSpinner}
                      color="green"
                    />
                  )}
                </div>
              </Stack>
            </Box>
          </Flex>
          <Table variant="unstyled" width="100%">
            <Thead>
              <Tr>
                <Th>Action</Th>
                <Th>Name</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {plaidItemList &&
                plaidItemList.map((item) => (
                  <PlaidItem
                    key={item.id}
                    item={item}
                    customer_id={customer_id}
                    accountList={accountList}
                    setLoading={setLoading}
                    removePlaidItemFromList={removePlaidItemFromList}
                    refreshPlaidItemList={refreshPlaidItemList}
                  />
                ))}
            </Tbody>
          </Table>
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};

const PlaidItem = ({
  item,
  customer_id,
  accountList,
  setLoading,
  removePlaidItemFromList,
  refreshPlaidItemList,
}) => {
  const { profile } = useCurrentProfile();

  const hasError = item.plaiditemerror_set.some(
    (error) => error.code === "ITEM_LOGIN_REQUIRED"
  );

  return (
    <Tr key={item.id}>
      <Td width="100px">
        <HStack>
          <ClientPLaidItemDelete
            customer_id={customer_id}
            plaiditem_id={item.id}
            removePlaidItemFromList={removePlaidItemFromList}
          />
          {false && hasError && (
            <LinkPlaid
              customer_id={customer_id}
              refreshList={refreshPlaidItemList}
              setLoading={setLoading}
              plaid_refresh={true}
              plaiditem_id={item.id}
            />
          )}
          {item.plaiditemerror_set.map((error) => (
            <Tooltip key={error.id} label={error.message} fontSize="md">
              <AlertCircle color="red" />
            </Tooltip>
          ))}
        </HStack>
      </Td>
      <Td>
        <b>{item.name}</b>
      </Td>
      <Td>
        <Table variant="unstyled" size="sm" width="100%">
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Account Name</Th>
              <Th>QB Name</Th>
              {profile?.hasAccountingAccess && <Th>Account Link</Th>}
              <Th>Type</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {item.bankaccount_set.map((account) => (
              <BankAccount
                key={account.id}
                account={account}
                accountList={accountList}
                setLoading={setLoading}
              />
            ))}
          </Tbody>
        </Table>
      </Td>
    </Tr>
  );
};

const BankAccount = ({ account, accountList, setLoading }) => {
  const { profile } = useCurrentProfile();
  const context = React.useContext(Context);
  const updateBankAccount = (selectedAccount) => {
    setLoading(true);

    const formattedAccountData = { accounting_account_id: selectedAccount };

    axios
      .patch(`/api/v2/bankaccounts/${account.id}/`, formattedAccountData)
      .then((res) => {
        setLoading(false);
        context.fireToast("Success", "Account updated", "success");
      })
      .catch((err) => console.log(err));
  };

  return (
    <Tr key={account.id}>
      <Td>
        {account.plaid_linked ? (
          <FontAwesomeIcon className="float-end" icon={faLink} color="green" />
        ) : (
          <FontAwesomeIcon className="float-end" icon={faUnlink} color="red" />
        )}
      </Td>
      <Td>{account.name}</Td>
      <Td>
        <EditQuickbooksName
          bankAccountId={account.id}
          initialName={
            account.quickbooks_name ? account.quickbooks_name : "..."
          }
        />
      </Td>
      {profile?.hasAccountingAccess && (
        <Td>
          <DropDown
            name="account"
            options={accountList}
            selectedValue={account.accounting_account?.id}
            onChange={(account) => updateBankAccount(account.value)}
            w="350px"
          />
        </Td>
      )}
      <Td>{account.subtype}</Td>
      <Td>
        <PlaidBankTransactionExport account_id={account.id} />
      </Td>
    </Tr>
  );
};

const ViewSubscriptionModal = ({
  isOpen,
  onClose,
  loading,
  subscription,
  setSubscription,
  customer_id,
}) => {
  const cancelSubscription = () => {
    axios
      .delete(
        `/api/v2/customers/${customer_id}/subscriptions/${subscription.id}/`,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setSubscription({});
        onClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {subscription?.id && (
            <Text>
              {titleCase(subscription.status)} since {subscription.start_date}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button
            type="button"
            isDisabled={loading}
            ml={3}
            onClick={cancelSubscription}
          >
            {loading ? <Spinner size="sm" /> : "End Subscription"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const StartSubscriptionModal = ({
  isOpen,
  onClose,
  loading,
  setSubscription,
  customer_id,
  plaidItemList,
}) => {
  const [data, setData] = useState({ day_of_month: 25 });

  const handleSubmit = (event) => {
    event.preventDefault();
    data.customer = customer_id;
    axios
      .post(
        `/api/v2/customers/${customer_id}/subscriptions/`,
        data,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setSubscription(res.data);
        onClose();
      })
      .catch((err) => console.log(err));
  };

  const bankAccounts = plaidItemList
    ?.map((item) =>
      item.bankaccount_set.map((bankAccount) => {
        return {
          value: bankAccount.id,
          label: bankAccount.name,
        };
      })
    )
    .flat();

  const handleInputChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleDayChange = (_, day) => {
    const name = "day_of_month";
    setData({ ...data, [name]: day });
  };

  const handleSelectBankAccount = (bankaccount) => {
    setData({ ...data, bankaccount: bankaccount.value });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="subscribeForm" onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel htmlFor="bankaccount">Bank Account</FormLabel>
              <DropDown
                name="bankaccount"
                options={bankAccounts}
                selectedValue={data.parent}
                onChange={handleSelectBankAccount}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="legal_name">Legal Name</FormLabel>
              <Input
                type="text"
                name="legal_name"
                defaultValue={data.legal_name}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="day_of_month">Day of Month</FormLabel>

              <NumberInput
                name="day_of_month"
                value={data.day_of_month}
                onChange={handleDayChange}
                required
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input
                type="date"
                name="start_date"
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form="subscribeForm"
            isDisabled={loading}
            ml={3}
          >
            {loading ? <Spinner size="sm" /> : "Subscribe"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
