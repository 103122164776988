import {
  border,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function customCurrencyFormat(numStr, percentage = false) {
  let num = parseFloat(numStr);
  if (isNaN(num)) return numStr;

  const isNegative = num < 0;

  let absNum = Math.abs(num).toFixed(2);

  let [integerPart, decimalPart] = absNum.split(".");

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const formattedNumber =
    decimalPart !== "00" ? `${integerPart}.${decimalPart}` : integerPart;

  if (percentage) {
    return isNegative ? `-${formattedNumber}%` : `${formattedNumber}%`;
  } else {
    return isNegative ? `-$${formattedNumber}` : `$${formattedNumber}`;
  }
}

const ExpenseBreakdown = ({
  customer_id,
  dashDate,
  setExpenseBreakdown,
  title,
  type,
  setTab,
}) => {
  const formattedDate = dashDate.toISOString().split("T")[0];
  const [metricGraphData, setMetricGraphData] = useState({
    values: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    date_interval: ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
  });
  const [selectedPeriod, setSelectedPeriod] = useState("month");

  useEffect(() => {
    // Create a cancel token source each time the effect runs
    const cancelTokenSource = axios.CancelToken.source();

    const refreshMetricGraphData = (id) => {
      if (id) {
        axios
          .get(
            `/api/v2/customers/${id}/expenseoverviewgraph/${type}/${selectedPeriod}/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              cancelToken: cancelTokenSource.token, // Attach cancel token to request
            }
          )
          .then((res) => {
            setMetricGraphData(res.data); // Set the response data to the state
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log("Request canceled", err.message); // Log the cancellation message
            }
          });
      }
    };

    refreshMetricGraphData(customer_id); // Call the function with the customer_id

    // Cleanup function: cancel the ongoing request on state change
    return () => {
      cancelTokenSource.cancel(
        "Request canceled due to state change or component unmount"
      );
    };
  }, [customer_id, formattedDate, selectedPeriod]);

  const data = {
    labels: metricGraphData
      ? metricGraphData.date_interval
      : ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
    datasets: [
      {
        type: "line",
        label: "Expense Trend Line",
        borderColor: "#FF5A5C",
        color: "#FF5A5C",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return "#FF5A5C";
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "rgba(255, 90, 92, 0.7)");
          gradient.addColorStop(1, "rgba(255, 90, 92, 0.1)");
          return gradient;
        },
        borderWidth: 3,
        pointRadius: 0,
        fill: true,
        data: metricGraphData
          ? metricGraphData.values
          : [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
        yAxisID: "y",
        order: "1",
        hoverRadius: 4,
        pointHitRadius: 30,
        borderCapStyle: "round",
        borderDash: [3, 2],
        PointStyle: "circle",
        tension: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            if (value < 1000) {
              return value % 1 === 0 ? `$${value}` : `$${value.toFixed(2)}`;
            } else {
              return value % 1 === 0
                ? `${(value / 1000).toFixed(0)}k`
                : `${(value / 1000).toFixed(1)}k`;
            }
          },
        },
        min: 0,
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: true,
          tickLength: 10,
          color: "#CCCCCC",
          lineWidth: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        align: "end",
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        intersect: false,
        position: "nearest",
        backgroundColor: "white",
        borderColor: "#e2e8f0",
        boxShadow: "md",
        borderWidth: 1,
        displayColors: true,
        xAlign: "left",
        yAlign: "center",
        caretSize: 0,
        caretPadding: 20,
        paddingLeft: 12,
        fontWeight: 100,
        fontSize: 12,
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            const label = tooltipItem.label;

            return ` ${
              selectedPeriod === "year"
                ? label
                : label +
                  "/" +
                  (dashDate.getMonth() + 1).toString().padStart(2, "0")
            }: ${customCurrencyFormat(value)}`;
          },

          labelColor: function (context) {
            return {
              borderColor: "#FF5A5C",
              backgroundColor: "#FF5A5C",
              borderColor: "white",
              borderWidth: 0,
              borderRadius: 6,
              align: "center",
            };
          },
        },
        titleFont: {
          size: 0, // Hide title font
        },
        bodyFont: {
          size: 12,
          weight: "bold",
        },
        bodyColor: "black",
        boxWidth: 14,
        boxHeight: 14,
        boxBorder: 0,
      },
    },
    layout: {
      padding: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      },
    },
  };

  return (
    <Stack>
      <Card borderRadius={0} width="100%" h="75px" border="1px solid #e0e0e0">
        <SimpleGrid templateColumns="2fr 2fr" top={"50%"} padding={"20px"}>
          <Box
            gridRow={1}
            gridColumn={1}
            textAlign={"left"}
            fontWeight={500}
            fontSize={"25px"}
          >
            {" "}
            {title} Breakdown
          </Box>
          <Box gridRow={1} gridColumn={2} textAlign={"right"}>
            <ButtonGroup isAttached variant="outline">
              <Button
                _hover={{
                  background: "initial",
                  boxShadow: "none",
                  opacity: "100%",
                  backgroundColor:
                    selectedPeriod === "month" ? "#536DEC" : "white",
                }}
                backgroundColor={
                  selectedPeriod === "month" ? "#536DEC" : "white"
                }
                textColor={selectedPeriod === "month" ? "white" : "#536DEC"}
                onClick={() => setSelectedPeriod("month")}
              >
                Current Month
              </Button>
              <Button
                _hover={{
                  background: "initial",
                  boxShadow: "none",
                  opacity: "100%%",
                  backgroundColor:
                    selectedPeriod === "year" ? "#536DEC" : "white",
                }}
                backgroundColor={
                  selectedPeriod === "year" ? "#536DEC" : "white"
                }
                textColor={selectedPeriod === "year" ? "white" : "#536DEC"}
                onClick={() => setSelectedPeriod("year")}
              >
                Year to Date
              </Button>
            </ButtonGroup>
          </Box>
        </SimpleGrid>
      </Card>
      <Stack align={"center"}>
        <Box width="95%">
          <Text
            textColor={"#536DEC"}
            cursor={"pointer"}
            fontSize={"17px"}
            paddingBottom={"15px"}
            paddingTop={"10px"}
            onClick={() => (
              setExpenseBreakdown(false), setTab("performanceReview")
            )}
          >
            {" "}
            <FontAwesomeIcon icon={faLongArrowLeft} strokeWidth={0.1} /> Back to
            Metric View
          </Text>
          <Card height={"300px"} width={"100%"}>
            <CardHeader paddingBottom={0}>
              <SimpleGrid templateColumns={"2fr 2fr"}>
                <Text fontSize={"17px"} textAlign={"left"}>{`${
                  selectedPeriod === "year"
                    ? "12-Month"
                    : dashDate.toLocaleString("default", {
                        month: "long",
                      })
                } ${title} Expense Trend`}</Text>
                <Box textAlign={"right"} position={"relative"}>
                  <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    position={"absolute"}
                    bottom={0}
                    right={0}
                  >
                    <Box
                      width="18px"
                      borderRadius={"5px"}
                      height="5px"
                      padding={"0 4px"}
                      backgroundColor="#FF5A5C"
                      marginRight="5px"
                    />
                    <Text fontSize={"14px"}>Expense Trend Line</Text>
                  </Flex>
                </Box>
              </SimpleGrid>
            </CardHeader>
            <CardBody>
              <Line
                data={data}
                options={options}
                plugins={[
                  {
                    afterDraw: (chart) => {
                      if (chart.tooltip?._active?.length) {
                        let x = chart.tooltip._active[0].element.x;
                        let yAxis = chart.scales.y;
                        let ctx = chart.ctx;
                        ctx.save();
                        ctx.beginPath();
                        ctx.setLineDash([5, 5]);
                        ctx.moveTo(x, yAxis.top);
                        ctx.lineTo(x, yAxis.bottom);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = "black";
                        ctx.stroke();
                        ctx.restore();
                      }
                    },
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ExpenseBreakdown;
