import React, { useState } from "react";
import axios from "axios";
import { Button, Tr, Td, Spinner, HStack, Checkbox } from "@chakra-ui/react";
import { VendorSelect } from "../account/vendorDropDown";
import {
  isTransactionAdded,
  isTransactionLinked,
  oppositeType,
  renderAmount,
} from "../../utils";
import { DropDown } from "../generic/dropDown";
import { CheckCircle } from "react-feather";

export const TransactionList = ({
  transactions,
  bankAccount,
  accountList,
  replaceTransaction,
  splitTransaction,
  linkTransaction,
  customer_id,
  recon = false,
}) => {
  const [loading, setLoading] = useState([]);
  const [selected, setSelected] = useState([]);

  const getSelectedAccount = (transaction) => {
    for (let entry of transaction.journal_entries) {
      if (entry.account !== bankAccount.accounting_account.id) {
        return entry.account;
      }
    }
  };

  const getTransactionUpdateData = (account, transaction) => {
    var data = { journal_entries: [] };
    if (transaction.journal_entries.length > 0) {
      var entry = transaction.journal_entries.find((e) => {
        return e.account !== bankAccount.accounting_account.id;
      });
      entry.account = account.value;
      data.journal_entries.push(entry);
    } else {
      const account_type = bankAccount.accounting_account.account_type;
      let bank_account_type =
        transaction.amount <= 0 ? account_type : oppositeType(account_type);
      if (account_type === "credit") {
        bank_account_type =
          transaction.amount <= 0 ? oppositeType(account_type) : account_type;
      }
      data.journal_entries.push({
        customer: customer_id,
        account: bankAccount.accounting_account.id,
        entry_type: bank_account_type,
        date: transaction.date,
        amount: Math.abs(transaction.amount),
        description: transaction.name,
        added: false,
      });
      data.journal_entries.push({
        customer: customer_id,
        account: account.value,
        entry_type: oppositeType(bank_account_type),
        date: transaction.date,
        amount: Math.abs(transaction.amount),
        description: transaction.name,
        added: false,
      });
    }
    return data;
  };

  const updateJournalEntry = (account, transaction) => {
    if (isSelected(transaction)) {
      const data = transactions
        .filter((t) => isSelected(t))
        .map((t) => {
          var tdata = getTransactionUpdateData(account, t);
          tdata.transaction_id = t.id;
          return tdata;
        });

      bulkUpdateTransactions(data);
    } else {
      const data = getTransactionUpdateData(account, transaction);
      updateTransaction(transaction, data);
    }
  };

  const updateVendor = (vendor, transaction) => {
    if (isSelected(transaction)) {
      const data = transactions
        .filter((t) => isSelected(t))
        .map((t) => {
          return { transaction_id: t.id, vendor: vendor.value };
        });
      bulkUpdateTransactions(data);
    } else {
      var data = { vendor: vendor.value };
      if (transaction.journal_entries.length > 0) {
        data["id"] = transaction.journal_entries[0].id;
      }
      updateTransaction(transaction, data);
    }
  };

  const addJournalEntry = (transaction) => {
    if (isSelected(transaction)) {
      const data = transactions
        .filter((t) => isSelected(t))
        .map((t) => {
          var entries = [];
          for (let entry of t.journal_entries) {
            entry.added = true;
            entries.push(entry);
          }
          return { transaction_id: t.id, journal_entries: entries };
        });
      bulkUpdateTransactions(data, "remove");
    } else {
      var data = { journal_entries: [] };
      for (let entry of transaction.journal_entries) {
        entry.added = true;
        data.journal_entries.push(entry);
      }
      updateTransaction(transaction, data, "remove");
    }
  };

  const updateTransaction = (transaction, data, action = "replace") => {
    setLoading((prevList) => [...prevList, transaction.id]);
    axios
      .patch(
        `/api/v2/bankaccounts/${transaction.bankaccount}/transactions/${transaction.id}/`,
        data
      )
      .then((res) => {
        replaceTransaction(res.data, action);
        setLoading((prevList) => prevList.filter((t) => transaction.id !== t));
      })
      .catch((err) => console.log(err));
  };

  const bulkUpdateTransactions = (data, action = "replace") => {
    const ids = data.map((t) => t.id);
    setLoading((prevList) => prevList.concat([...ids]));
    axios
      .patch(`/api/v2/bankaccounts/${bankAccount.id}/bulktransactions/`, data)
      .then((res) => {
        replaceTransaction(res.data, action);
        setLoading((prevList) => prevList.filter((t) => ids.indexOf(t) !== -1));
      })
      .catch((err) => console.log(err));
  };

  const isLoading = (t) => {
    return loading.indexOf(t.id) !== -1;
  };

  const isSelected = (t) => {
    return selected.indexOf(t.id) !== -1;
  };

  const selectTransaction = (transaction, e) => {
    if (e.target.checked && e.nativeEvent.shiftKey && selected.length > 0) {
      var on = false;
      for (let t of transactions) {
        if (selected.indexOf(t.id) !== -1) {
          on = true;
        }

        if (on) {
          setSelected((prev) => [...prev, t.id]);
        }

        if (t.id === transaction.id) {
          on = false;
        }
      }
    } else {
      if (e.target.checked) {
        setSelected((prev) => [...prev, transaction.id]);
      } else {
        setSelected((prev) => prev.filter((id) => id !== transaction.id));
      }
    }
  };

  return (
    transactions &&
    transactions.map((transaction) => (
      <Tr key={transaction.id}>
        <Td>
          <Checkbox
            isChecked={isSelected(transaction)}
            onChange={(e) => selectTransaction(transaction, e)}
          />
        </Td>
        <Td>{transaction.date}</Td>
        <Td>{transaction.name}</Td>
        <Td w="350px">
          <DropDown
            name="account"
            options={accountList}
            selectedValue={getSelectedAccount(transaction)}
            onChange={updateJournalEntry}
            metaData={transaction}
          />
        </Td>
        <Td w="350px">
          <VendorSelect
            selectedVendorID={transaction.vendor}
            onChange={updateVendor}
            metaData={transaction}
          />
        </Td>
        <Td>
          {transaction.amount > 0 && renderAmount(Math.abs(transaction.amount))}
        </Td>
        <Td>
          {transaction.amount <= 0 &&
            renderAmount(Math.abs(transaction.amount))}
        </Td>
        <Td>
          <HStack p="0" direction="row" spacing={2} align="center">
            {splitTransaction && (
              <Button
                variant="outline"
                onClick={() => splitTransaction(transaction)}
              >
                Split
              </Button>
            )}
            {linkTransaction && (
              <Button
                variant="outline"
                onClick={() => linkTransaction(transaction)}
              >
                Link
              </Button>
            )}
            {isTransactionLinked(transaction) &&
              !isTransactionAdded(transaction) &&
              !isLoading(transaction) && (
                <Button
                  colorScheme="brand"
                  onClick={() => addJournalEntry(transaction)}
                >
                  Add
                </Button>
              )}
            {recon && isTransactionAdded(transaction) && (
              <CheckCircle color={"green"} />
            )}
            {isLoading(transaction) && <Spinner color="brand.600" />}
          </HStack>
        </Td>
      </Tr>
    ))
  );
};
