import React, { useState, useEffect } from "react";
import axios from "axios";
import { LinkButton } from "./linkButton";

export const LinkPlaid = ({
  customer_id,
  refreshList,
  setLoading,
  plaid_refresh,
  plaiditem_id,
}) => {
  const [publicToken, setPublicToken] = useState("");

  useEffect(() => {
    let params = {};
    if (plaiditem_id) {
      params.plaiditem_id = plaiditem_id;
    }

    axios
      .get(`/api/v2/create_link_token/`, {
        headers: { "Content-Type": "application/json" },
        params: params,
        withCredentials: true,
      })
      .then((res) => {
        setPublicToken(res.data.link_token);
      })
      .catch((err) => console.log(err));
  }, [plaiditem_id]);

  return (
    <div>
      {publicToken && (
        <LinkButton
          customer_id={customer_id}
          public_token={publicToken}
          refreshList={refreshList}
          setLoading={setLoading}
          plaid_refresh={plaid_refresh}
        />
      )}
    </div>
  );
};
