import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import CustomTableContainer from "../../theme/components/tableContainer";
import axios from "axios";
import { DropDown } from "../generic/dropDown";
import { isTransactionAdded, LoadingTable, renderAmount } from "../../utils";
import { Context } from "../../ContextWrapper";
import { TransactionList } from "../account/transactionList";

export const ClientReconciliations = ({ customer_id }) => {
  const [reconciliationList, setReconciliationList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [selectedRecon, setSelectedRecon] = useState(null);

  const refreshReconciliationList = (customer_id) => {
    if (customer_id) {
      axios
        .get(
          `/api/v2/customers/${customer_id}/reconciliations/`,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setReconciliationList(res.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const refreshAccountList = (customer_id) => {
    axios
      .get(
        `/api/v2/customers/${customer_id}/accounts/?type__name__in=Bank,Credit Card&is_main_account=false`,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setAccountList(
          res.data.map((account) => {
            return {
              value: account.id,
              label: account.number + " - " + account.name,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    refreshReconciliationList(customer_id);
    refreshAccountList(customer_id);
  }, [customer_id]);

  const handleStart = (event, reconData, setReconData) => {
    event.preventDefault();
    axios
      .post(
        `/api/v2/customers/${customer_id}/reconciliations/`,
        {
          customer: customer_id,
          account: reconData.account,
          bankaccount_id: reconData.bankaccount,
          start_date: reconData.openingDate,
          opening_balance: reconData.openingBalance,
          end_date: reconData.endDate,
          closing_balance: reconData.closingBalance,
        },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setReconciliationList([...reconciliationList, res.data]);
        setLoading(false);
        onClose();
        setReconData({});
        setSelectedRecon(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getAccountName = (accountId) => {
    for (let account of accountList) {
      if (account.value === accountId) {
        return account.label;
      }
    }
  };

  const renderDate = (dateTime) => {
    return dateTime.split("T")[0];
  };

  const updateReconciliation = (recon) => {
    setReconciliationList(
      reconciliationList.map((r) => (r.id === recon.id ? recon : r))
    );
    setSelectedRecon(null);
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <Stack direction="column" spacing={2} align="center">
        <CustomTableContainer width="100%">
          {!selectedRecon && (
            <>
              <StartReconciliationModal
                isOpen={isOpen}
                onClose={onClose}
                accountList={accountList}
                loading={loading}
                handleSubmit={handleStart}
                customer_id={customer_id}
              />
              <Flex pb="5">
                <Box>
                  <Heading as="h3" size="lg">
                    Reconciliations
                  </Heading>
                </Box>
                <Spacer />
                <Box>
                  <Stack direction="row" spacing={6} align="center">
                    <Button onClick={onOpen}>Start</Button>
                  </Stack>
                </Box>
              </Flex>
              <Box maxHeight="calc(100vh - 350px)" bg="white" overflowX="auto">
                <Table variant="unstyled">
                  <Thead position="sticky" top={-1} zIndex={1}>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Account</Th>
                      <Th>Closing Date</Th>
                      <Th>Closing Balance</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {reconciliationList?.map((recon) => (
                      <Tr key={recon.id}>
                        <Td>{renderDate(recon.created_on)}</Td>
                        <Td>{getAccountName(recon.account)}</Td>
                        <Td>{recon.end_date}</Td>
                        <Td>{recon.closing_balance}</Td>
                        <Td>{recon.status}</Td>
                        <Td>
                          {recon.status === "started" && (
                            <Button onClick={() => setSelectedRecon(recon)}>
                              Complete
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </>
          )}
          {selectedRecon && (
            <AccountReconciliation
              customer_id={customer_id}
              recon={selectedRecon}
              updateReconciliation={updateReconciliation}
              accountName={getAccountName(selectedRecon.account)}
            />
          )}
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};

const StartReconciliationModal = ({
  isOpen,
  onClose,
  accountList,
  loading,
  handleSubmit,
  customer_id,
}) => {
  const [reconData, setReconData] = useState({});
  useEffect(() => {
    setReconData({});
  }, []);

  const handleSelectAccount = (account) => {
    axios
      .get(
        `/api/v2/customers/${customer_id}/reconciliationdata/${account.value}/`,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setReconData({
          ...reconData,
          account: account.value,
          openingBalance: res.data.start_balance,
          openingDate: res.data.start_date,
          bankaccount: res.data.bankaccount_id,
        });
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReconData({ ...reconData, [name]: value });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Start Reconciliation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            id="reconForm"
            onSubmit={(e) => handleSubmit(e, reconData, setReconData)}
          >
            <FormControl>
              <FormLabel htmlFor="account">Account</FormLabel>
              <DropDown
                name="account"
                options={accountList}
                selectedValue={reconData.account}
                onChange={handleSelectAccount}
              />
            </FormControl>
            {reconData.openingDate && (
              <Text p={2} color="brand.600">
                Last statement ending {reconData.openingDate}
              </Text>
            )}
            <FormControl>
              <FormLabel htmlFor="openingBalance">Opening balance</FormLabel>
              <Text pb={2}>{reconData.openingBalance}</Text>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="closingBalance">Closing balance</FormLabel>
              <Input
                type="text"
                name="closingBalance"
                defaultValue={reconData.closingBalance}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>End Date</FormLabel>
              <Input type="date" name="endDate" onChange={handleInputChange} />
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button type="submit" form="reconForm" isDisabled={loading} ml={3}>
            {loading ? <Spinner size="sm" /> : "Start"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const AccountReconciliation = ({
  customer_id,
  recon,
  updateReconciliation,
  accountName,
}) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextCursor, setNextCursor] = useState(null);
  const [previousCursor, setPreviousCursor] = useState(null);
  const [currentCursor, setCurrentCursor] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const context = React.useContext(Context);

  useEffect(() => {
    axios
      .get(`/api/v2/customers/${customer_id}/accounts/`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then((res) => {
        setAccountList(
          res.data.map((a) => ({
            value: a.id,
            label: a.number + " - " + a.name,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, [customer_id]);

  useEffect(() => {
    let cursorParam = null;
    if (currentCursor) {
      const urlObj = new URL(currentCursor);
      cursorParam = urlObj.searchParams.get("cursor");
    }

    axios
      .get(`/api/v2/bankaccounts/${recon.bankaccount.id}/transactions/`, {
        headers: { "Content-Type": "application/json" },
        params: {
          cursor: cursorParam,
          ordering: "date",
          date_after: recon.start_date,
          date_before: recon.end_date,
        },
        withCredentials: true,
      })
      .then((res) => {
        setTransactions(res.data.results);
        setNextCursor(res.data.next);
        setPreviousCursor(res.data.previous);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [recon, currentCursor]);

  const replaceTransaction = (t, _action) => {
    if (t.constructor === Array) {
      t.map((transaction) =>
        setTransactions((prev) =>
          prev.map((o) => (o.id === transaction.id ? transaction : o))
        )
      );
    } else {
      setTransactions(transactions.map((o) => (o.id === t.id ? t : o)));
    }
  };

  const payments = transactions.filter(
    (t) => t.amount > 0 && isTransactionAdded(t)
  );
  const paymentTotal = payments.reduce(
    (n, { amount }) => n + Math.abs(amount),
    0
  );

  const deposits = transactions.filter(
    (t) => t.amount <= 0 && isTransactionAdded(t)
  );
  const depositTotal = deposits.reduce(
    (n, { amount }) => n + Math.abs(amount),
    0
  );

  const clearedBalance = recon.opening_balance - paymentTotal + depositTotal;

  const submitRecon = () => {
    if (clearedBalance === Number(recon.closing_balance)) {
      axios
        .patch(
          `/api/v2/customers/${customer_id}/reconciliations/${recon.id}/`,
          {
            status: "completed",
          }
        )
        .then((res) => {
          updateReconciliation(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      context.fireToast(
        "Error",
        "Statement ending balance and cleared balance doesn't match.",
        "error"
      );
    }
  };

  return (
    <>
      <Flex pb="5">
        <Box>
          <Button onClick={() => updateReconciliation(recon)}>Back</Button>
        </Box>
        <Spacer />
        <Box>
          <Stack direction="column" spacing={6} align="center">
            <Text>Reconcile {accountName}</Text>
            <Text>Statement ending {recon.end_date}</Text>
          </Stack>
        </Box>
        <Spacer />
        <Box>
          <Button onClick={submitRecon}>Submit</Button>
        </Box>
      </Flex>
      <HStack pb="5">
        <VStack>
          <Text>Statement ending balance</Text>
          <Text>{renderAmount(recon.closing_balance)}</Text>
        </VStack>
        <VStack ps={100}>
          <Text>Cleared Balance</Text>
          <Text>{renderAmount(clearedBalance)}</Text>
        </VStack>
        <VStack
          ps={100}
          color={
            clearedBalance !== Number(recon.closing_balance) ? "red" : "black"
          }
        >
          <Text>Difference</Text>
          <Text>{renderAmount(recon.closing_balance - clearedBalance)}</Text>
        </VStack>
      </HStack>
      <Flex pb="5">
        <VStack>
          <Text>Beginning balance</Text>
          <Text>{renderAmount(recon.opening_balance)}</Text>
        </VStack>
        <Box p={4}>-</Box>
        <VStack>
          <Text>{payments.length} Payments</Text>
          <Text>{renderAmount(paymentTotal)}</Text>
        </VStack>
        <Box p={4}>+</Box>
        <VStack>
          <Text>{deposits.length} Deposits</Text>
          <Text>{renderAmount(depositTotal)}</Text>
        </VStack>
      </Flex>
      <Box maxHeight="calc(100vh - 350px)" bg="white" overflowX="auto">
        {loading && <LoadingTable />}
        {!loading && (
          <Table variant="unstyled">
            <Thead position="sticky" top={-1} zIndex={1}>
              <Tr>
                <Th w="40px"></Th>
                <Th>Date</Th>
                <Th>Description</Th>
                <Th>Account</Th>
                <Th>Vendor</Th>
                <Th>Spent</Th>
                <Th>Received</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              <TransactionList
                transactions={transactions}
                bankAccount={recon.bankaccount}
                accountList={accountList}
                replaceTransaction={replaceTransaction}
                customer_id={customer_id}
                recon={true}
              />
            </Tbody>
          </Table>
        )}
      </Box>
      <Stack direction="row" spacing={4} mt={4} justifyContent="center">
        {previousCursor && (
          <Button onClick={() => setCurrentCursor(previousCursor)}>
            Previous
          </Button>
        )}
        {nextCursor && (
          <Button onClick={() => setCurrentCursor(nextCursor)}>Next</Button>
        )}
      </Stack>
    </>
  );
};
