import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Context } from "../../ContextWrapper";
import CustomTableContainer from "../../theme/components/tableContainer";

function formatDate(dateString) {
  const date = new Date(dateString);

  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options);
}

export const MonthlyIndicator = ({ customer_id, year_id, turnBack }) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indicatorList, setIndicatorList] = useState([]);
  const [yearData, setYearData] = useState({});
  const [selectedIndicator, setSelectedIndicator] = useState({});
  const context = React.useContext(Context);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch Monthly Indicators
        const indicatorsResponse = await axios.get(
          `/api/v2/customers/${customer_id}/yearlybudget/${year_id}/monthlyindicators/`,
          { withCredentials: true }
        );
        setIndicatorList(indicatorsResponse.data);

        // Fetch Year Data
        const yearResponse = await axios.get(
          `/api/v2/customers/${customer_id}/yearlybudget/${year_id}`,
          { withCredentials: true }
        );
        setYearData(yearResponse.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [customer_id, year_id]);

  const handleSubmit = async (event, updatedIndicator = null) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (updatedIndicator) {
        const indicatorUpdateUrl = `/api/v2/customers/${customer_id}/yearlybudget/${yearData.id}/monthlyindicators/${updatedIndicator.id}/`;

        const indicatorResponse = await axios.patch(
          indicatorUpdateUrl,
          updatedIndicator
        );

        if (indicatorResponse.status < 300) {
          setIndicatorList((prevIndicators) =>
            prevIndicators.map((indicator) =>
              indicator.id === updatedIndicator.id
                ? indicatorResponse.data
                : indicator
            )
          );
          context.fireToast("Success", "Indicator has been updated", "success");
        } else {
          context.fireToast(
            "Error",
            indicatorResponse.request.responseText,
            "error"
          );
        }
      } else {
        const yearUpdateUrl = `/api/v2/customers/${customer_id}/yearlybudget/${yearData.id}/`;

        delete yearData.date;

        const yearResponse = await axios.patch(yearUpdateUrl, yearData);
        if (yearResponse.status < 300) {
          setYearData(yearResponse.data);
        } else {
          context.fireToast(
            "Error",
            yearResponse.request.responseText,
            "error"
          );
        }

        const updatedIndicators = await Promise.all(
          indicatorList.map(async (indicator) => {
            if (!indicator.id) return indicator; // Skip if ID is missing

            const indicatorUpdateUrl = `/api/v2/customers/${customer_id}/yearlybudget/${yearData.id}/monthlyindicators/${indicator.id}/`;
            const indicatorResponse = await axios.patch(
              indicatorUpdateUrl,
              indicator
            );

            if (indicatorResponse.status < 300) {
              return indicatorResponse.data; // Return the updated indicator
            } else {
              context.fireToast(
                "Error",
                indicatorResponse.request.responseText,
                "error"
              );
              return indicator; // Return the old indicator if there's an error
            }
          })
        );

        // Set the updated indicators state
        setIndicatorList(updatedIndicators);
        context.fireToast("Success", "Year data updated", "success");
      }
    } catch (err) {
      console.error("There was an error updating:", err);
      context.fireToast(
        "Error",
        "An error occurred during the update",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e, indicatorId) => {
    const { name, value } = e.target;

    setIndicatorList((prevList) =>
      prevList.map((indicator) =>
        indicator.id === indicatorId
          ? { ...indicator, [name]: value }
          : indicator
      )
    );
  };

  const handlePLInputChange = (e) => {
    const { name, value } = e.target;
    setYearData((prev) => ({ ...prev, [name]: value }));
  };

  const toggle = () => {
    setSelectedIndicator({});
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Stack textAlign={"center"} overflowX={"auto"} maxW={"100%"}>
      {loading && (
        <FontAwesomeIcon
          className="float-end fa-spin"
          icon={faSpinner}
          color="green"
        />
      )}
      <Stack align="center">
        <CustomTableContainer>
          <BudgetModal
            isOpen={isModalOpen}
            toggle={toggle}
            selectedIndicator={selectedIndicator}
            setSelectedIndicator={setSelectedIndicator}
            title="Comment for month"
            indicatorList={indicatorList}
            loading={loading}
            handleSubmit={handleSubmit}
          />
          <form id="indicatorForm" onSubmit={(e) => handleSubmit(e)}>
            <Flex pb="5">
              <Box>
                <Heading as="h3" size="lg">
                  Customer Monthly Indicators
                </Heading>
              </Box>
              <Spacer />
              <Box>
                <Stack direction="row" spacing={6} align="center">
                  <Button
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    Create Comment
                  </Button>
                  <Button type="submit" form="indicatorForm">
                    Save
                  </Button>
                </Stack>
              </Box>
            </Flex>
            <Box
              maxHeight="calc(100vh - 350px)"
              maxW={"calc(100vw - 500px)"}
              bg="white"
              overflowY={"auto"}
              overflowX={"auto"}
            >
              <Table variant="unstyled" width={"100%"}>
                <Thead position="sticky" top={-1} zIndex={1}>
                  <Tr>
                    <Th textAlign={"center"}>Accounts</Th>
                    <Th textAlign={"center"}>P&L %</Th>
                    {indicatorList ? (
                      indicatorList.map((indicator) => (
                        <Th
                          textAlign={"center"}
                          key={formatDate(indicator.date)}
                        >
                          {" "}
                          {formatDate(indicator.date)}
                        </Th>
                      ))
                    ) : (
                      <></>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td textAlign={"left"} key={"labels"}>
                      <VStack align={"start"}>
                        <Box p={"9px 0px"}>Income</Box>
                        <Box p={"9px 0px"}>COGS</Box>
                        <Box p={"9px 0px"}>Marketing</Box>
                        <Box p={"9px 0px"}>Team</Box>
                        <Box p={"9px 0px"}>Practice Cost</Box>
                        <Box p={"9px 0px"}>Overhead</Box>
                        <Box p={"9px 0px"}>Business Development</Box>
                        <Box p={"9px 0px"}>Taxes</Box>
                        <Box p={"9px 0px"}>Net Profit</Box>
                      </VStack>
                    </Td>
                    <Td key={yearData.id}>
                      <VStack>
                        <Box p={"9px 0px"}>-</Box>
                        <Box>
                          <Input
                            width={"75px"}
                            type="Box"
                            name="cogs_pl"
                            defaultValue={yearData.cogs_pl}
                            onChange={(e) => handlePLInputChange(e)}
                          />
                        </Box>
                        <Box>
                          <Input
                            width={"75px"}
                            type="Box"
                            name="marketing_pl"
                            defaultValue={yearData.marketing_pl}
                            onChange={(e) => handlePLInputChange(e)}
                          />
                        </Box>
                        <Box>
                          <Input
                            width={"75px"}
                            type="Box"
                            name="team_pl"
                            defaultValue={yearData.team_pl}
                            onChange={(e) => handlePLInputChange(e)}
                          />
                        </Box>
                        <Box>
                          <Input
                            width={"75px"}
                            type="Box"
                            name="practice_cost_pl"
                            defaultValue={yearData.practice_cost_pl}
                            onChange={(e) => handlePLInputChange(e)}
                          />
                        </Box>
                        <Box>
                          <Input
                            width={"75px"}
                            type="Box"
                            name="overhead_pl"
                            defaultValue={yearData.overhead_pl}
                            onChange={(e) => handlePLInputChange(e)}
                          />
                        </Box>
                        <Box>
                          <Input
                            width={"75px"}
                            type="Box"
                            name="business_development_pl"
                            defaultValue={yearData.business_development_pl}
                            onChange={(e) => handlePLInputChange(e)}
                          />
                        </Box>
                        <Box>
                          <Input
                            width={"75px"}
                            type="Box"
                            name="taxes_pl"
                            defaultValue={yearData.taxes_pl}
                            onChange={(e) => handlePLInputChange(e)}
                          />
                        </Box>
                        <Box>
                          <Input
                            width={"75px"}
                            type="Box"
                            name="profit_pl"
                            defaultValue={yearData.profit_pl}
                            onChange={(e) => handlePLInputChange(e)}
                          />
                        </Box>
                      </VStack>
                    </Td>

                    {indicatorList ? (
                      indicatorList.map((indicator) => (
                        <IndicatorRow
                          key={indicator.id}
                          indicator={indicator}
                          customer_id={customer_id}
                          handleInputChange={handleInputChange}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </form>
          <Stack align={"start"}>
            <Button
              width={"300px"}
              m={"10px 0px 0px 0px"}
              onClick={() => turnBack(false)}
            >
              Back to year select
            </Button>
          </Stack>
        </CustomTableContainer>
      </Stack>
    </Stack>
  );
};

const IndicatorRow = ({ indicator, handleInputChange }) => {
  return (
    <Td key={indicator.id}>
      <VStack>
        <Box>
          <Input
            width={"150px"}
            textAlign={"center"}
            type="Box"
            name="income_forecast"
            defaultValue={indicator.income_forecast}
            onChange={(e) => handleInputChange(e, indicator.id)}
          />
        </Box>
        <Box p={"9px 0px"}>{indicator.cogs_forecast}</Box>
        <Box p={"9px 0px"}>{indicator.marketing_forecast}</Box>
        <Box p={"9px 0px"}>{indicator.team_forecast}</Box>
        <Box p={"9px 0px"}>{indicator.practice_cost_forecast}</Box>
        <Box p={"9px 0px"}>{indicator.overhead_forecast}</Box>
        <Box p={"9px 0px"}>{indicator.business_development_forecast}</Box>
        <Box p={"9px 0px"}>{indicator.taxes_forecast}</Box>
        <Box p={"9px 0px"}>{indicator.net_operating_profit_forecast}</Box>
      </VStack>
    </Td>
  );
};

const BudgetModal = ({
  isOpen,
  toggle,
  selectedIndicator,
  setSelectedIndicator,
  title,
  indicatorList, // Array of indicators
  loading,
  handleSubmit,
}) => {
  const confirmText = "Update"; // Since we're updating, it's always "Update"
  const [selectedDate, setSelectedDate] = useState("");

  // Handle when the user selects a date
  const handleDateChange = (e) => {
    const date = e.target.value;
    setSelectedDate(date);

    // Find the corresponding indicator for the selected date
    const foundIndicator = indicatorList.find(
      (indicator) =>
        new Date(indicator.date).toISOString().split("T")[0] === date
    );

    if (foundIndicator) {
      setSelectedIndicator(foundIndicator); // Set the indicator data for the selected date
    } else {
      setSelectedIndicator({}); // Clear the form if no indicator found
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedIndicator({ ...selectedIndicator, [name]: value });
  };

  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxHeight={"500px"} overflowY="auto">
          <form
            id="IndicatorForm"
            onSubmit={(e) => handleSubmit(e, selectedIndicator)}
          >
            <FormControl>
              <FormLabel>Select Date</FormLabel>
              <Select
                placeholder="Select date"
                value={selectedDate}
                onChange={handleDateChange}
              >
                {indicatorList.map((indicator) => (
                  <option
                    key={indicator.id}
                    value={new Date(indicator.date).toISOString().split("T")[0]}
                  >
                    {formatDate(indicator.date)}
                  </option>
                ))}
              </Select>
            </FormControl>

            {selectedIndicator && (
              <>
                <Text
                  fontSize="20px"
                  textDecoration={"underline"}
                  padding={"10px 0px"}
                >
                  Resource Recommendation
                </Text>

                <FormControl>
                  <FormLabel htmlFor="first_recommended_resource">
                    First Resource Reccomendation
                  </FormLabel>
                  <Input
                    type="text"
                    name="first_recommended_resource"
                    placeholder="Enter first resource"
                    value={selectedIndicator.first_recommended_resource || ""}
                    onChange={handleInputChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="second_recommended_resource">
                    Second Resource Reccomendation
                  </FormLabel>
                  <Input
                    type="text"
                    name="second_recommended_resource"
                    placeholder="Enter second resource"
                    value={selectedIndicator.second_recommended_resource || ""}
                    onChange={handleInputChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="third_recommended_resource">
                    Third Resource Reccomendation
                  </FormLabel>
                  <Input
                    type="text"
                    name="third_recommended_resource"
                    placeholder="Enter third resource"
                    value={selectedIndicator.third_recommended_resource || ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <Text
                  fontSize="20px"
                  textDecoration={"underline"}
                  padding={"10px 0px"}
                >
                  Performance Overview
                </Text>

                <FormControl>
                  <Textarea
                    h={"150px"}
                    type="text"
                    name="performance_overview"
                    placeholder="Enter performance review message here."
                    value={selectedIndicator.performance_overview || ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form="IndicatorForm"
            isDisabled={loading}
            ml={3}
          >
            {loading ? <Spinner size="sm" /> : confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
