import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Context } from "../../ContextWrapper";
import CustomTableContainer from "../../theme/components/tableContainer";
import { MonthlyIndicator } from "./monthlyIndicator";

function getYearFromDate(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime()) ? String(date.getFullYear()) : "";
}

export const YearlyBudget = ({ customer_id }) => {
  const [loading, setLoading] = useState(true);
  const [budgetList, setBudgetList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentBudget, setCurrentBudget] = useState({});
  const [toggleMonths, setToggleMonths] = useState(false);
  const [selectedYear, setSelectedYear] = useState();

  const context = React.useContext(Context);

  useEffect(() => {
    const refreshAccountList = (customer_id) => {
      const url = `/api/v2/customers/${customer_id}/yearlybudget/`;
      axios
        .get(
          url,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setBudgetList(res.data);
        })
        .catch((err) => console.log(err));
    };

    refreshAccountList(customer_id);
    setLoading(false);
  }, [customer_id]);

  const toggle = () => {
    setCurrentBudget({});
    setIsModalOpen(!isModalOpen);
  };

  const startCreateBudget = () => {
    setCurrentBudget({});
    setIsModalOpen(true);
  };

  const startUpdateBudget = (yearlybudget) => {
    setCurrentBudget(yearlybudget);
    setIsModalOpen(true);
  };

  const toggleDelete = () => {
    setCurrentBudget({});
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const startDeleteAccount = (yearlybudget) => {
    setCurrentBudget(yearlybudget);
    setIsDeleteModalOpen(true);
  };

  const toggleView = () => {
    setToggleMonths(true);
  };

  const handleSubmit = async (event, yearlybudget) => {
    event.preventDefault();
    setLoading(true);

    const year = yearlybudget.date;
    if (!year) {
      context.fireToast("Error", "Year is required.", "error");
      setLoading(false);
      return;
    }

    if (year.length === 4 && /^\d{4}$/.test(year)) {
      yearlybudget.date = `${year}-01-01`;
    } else {
      context.fireToast(
        "Error",
        "Invalid Year format. Expected format: YYYY.",
        "error"
      );
      setLoading(false);
      return;
    }

    if (isNaN(new Date(yearlybudget.date).getTime())) {
      context.fireToast("Error", "Invalid date.", "error");
      setLoading(false);
      return;
    }

    let url = `/api/v2/customers/${customer_id}/yearlybudget/`;

    try {
      let res;
      if (yearlybudget.id) {
        url += `${yearlybudget.id}/`;
        yearlybudget.date = String(yearlybudget.date);
        res = await axios.patch(url, yearlybudget);

        if (res.status < 300) {
          setBudgetList(
            budgetList.map((o) => (o.id === res.data.id ? res.data : o))
          );
          context.fireToast(
            "Success",
            "Yearly budget has been updated",
            "success"
          );
          toggle();
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
      } else {
        yearlybudget["customer"] = customer_id;
        res = await axios.post(url, yearlybudget);

        if (res.status < 300) {
          setBudgetList([...budgetList, res.data]);
          context.fireToast(
            "Success",
            "Yearly budget has been created",
            "success"
          );
          toggle();
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
      }
    } catch (err) {
      console.error("There was an error updating the Yearly budget!", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (yearlybudget) => {
    let url = `/api/v2/customers/${customer_id}/yearlybudget/`;

    url += `${yearlybudget.id}/`;

    axios
      .delete(url)
      .then((res) => {
        if (res.status < 300) {
          setBudgetList(budgetList.filter((o) => o.id !== yearlybudget.id));
          context.fireToast(
            "Success",
            "yearlybudget has been deleted",
            "success"
          );
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
        setLoading(false);
        toggleDelete();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      {!toggleMonths ? (
        <>
          {loading && (
            <FontAwesomeIcon
              className="float-end fa-spin"
              icon={faSpinner}
              color="green"
            />
          )}
          <Stack direction="column" spacing={2} align="center">
            <CustomTableContainer width="100%">
              <BudgetModal
                isOpen={isModalOpen}
                toggle={toggle}
                title="Yearly Budget"
                yearlybudget={currentBudget}
                loading={loading}
                handleSubmit={handleSubmit}
              />
              <BudgetDeleteModal
                colorScheme="red"
                isOpen={isDeleteModalOpen}
                toggle={toggleDelete}
                yearlybudget={currentBudget}
                loading={loading}
                handleDelete={handleDelete}
              />
              <Flex pb="5">
                <Box>
                  <Heading as="h3" size="lg">
                    Customer Yearly Budget
                  </Heading>
                </Box>
                <Spacer />
                <Box>
                  <Stack direction="row" spacing={6} align="center">
                    <Button
                      onClick={startCreateBudget}
                      leftIcon={<AddIcon boxSize={3} />}
                    >
                      Create Yearly Budget
                    </Button>
                  </Stack>
                </Box>
              </Flex>
              <Box
                maxHeight="calc(100vh - 350px)"
                bg="white"
                overflowY={"auto"}
              >
                <Table variant="unstyled" overflowY={"auto"}>
                  <Thead position="sticky" top={-1} zIndex={1}>
                    <Tr>
                      <Th>Year</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {budgetList.map((yearlybudget) => (
                      <BudgetRow
                        key={yearlybudget.id}
                        yearlybudget={yearlybudget}
                        startUpdateBudget={startUpdateBudget}
                        toggle={toggleView}
                        year={setSelectedYear}
                        startDeleteBudget={startDeleteAccount}
                        customer_id={customer_id}
                      />
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </CustomTableContainer>
          </Stack>
        </>
      ) : (
        <MonthlyIndicator
          customer_id={customer_id}
          year_id={selectedYear}
          turnBack={setToggleMonths}
        />
      )}
    </Container>
  );
};

const BudgetRow = ({
  yearlybudget,
  startUpdateBudget,
  toggle,
  year,
  startDeleteBudget,
  customer_id,
}) => {
  const isEditable = yearlybudget.customer === customer_id;

  return (
    <>
      <Tr key={yearlybudget.id}>
        <Td>{getYearFromDate(yearlybudget.date)}</Td>
        <Td>
          {isEditable && (
            <HStack spacing={2}>
              <Button
                onClick={() => {
                  year(yearlybudget.id);
                  toggle();
                }}
              >
                View Months
              </Button>
              <Button onClick={() => startUpdateBudget(yearlybudget)}>
                Edit
              </Button>
              <Button
                colorScheme="red"
                onClick={() => startDeleteBudget(yearlybudget)}
              >
                Delete
              </Button>
            </HStack>
          )}
        </Td>
      </Tr>
    </>
  );
};

const BudgetDeleteModal = ({
  isOpen,
  toggle,
  yearlybudget,
  loading,
  handleDelete,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete yearlybudget for period{" "}
          {getYearFromDate(yearlybudget.date)}?
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            onClick={() => handleDelete(yearlybudget)}
            isDisabled={loading}
          >
            {loading ? "Processing..." : "Delete"}
          </Button>
          <Button onClick={toggle} ml={3}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const BudgetModal = ({
  isOpen,
  toggle,
  title,
  yearlybudget,
  loading,
  handleSubmit,
}) => {
  const confirmText = !yearlybudget.id ? "Create" : "Update";
  const [budgetData, setBudgetData] = useState({});

  useEffect(() => {
    setBudgetData({
      ...yearlybudget,
      date: getYearFromDate(yearlybudget.date),
    });
  }, [yearlybudget]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBudgetData({ ...budgetData, [name]: value });
  };

  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxHeight={"500px"} overflowY="auto">
          <form id="BudgetForm" onSubmit={(e) => handleSubmit(e, budgetData)}>
            <FormControl>
              <Text
                fontSize="20px"
                textDecoration={"underline"}
                padding={"10px 0px"}
              >
                {" "}
                Year{" "}
              </Text>
              <Input
                type="text"
                name="date"
                placeholder="Please type in a year."
                defaultValue={budgetData.date}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button type="submit" form="BudgetForm" isDisabled={loading} ml={3}>
            {loading ? <Spinner size="sm" /> : confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
